import { RawEditorSettings } from "tinymce";

const AppConfig: RawEditorSettings = {
  tinyMCEKey: 'g19zsntbuwy09f2yc51sut3ax33qpt8f59l3zrbwadlitbm3',
  tinyMCEOptions: {
    placeholder: 'Digite o conteúdo...',
    height: 350,
    menubar: false,
    plugins: [
      'insert image print preview paste searchreplace autolink',
      'save directionality code visualblocks visualchars',
      'nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools media',
      'textpattern noneditable help charmap quickbars emoticons',
    ],
    fullpage_default_doctype: '<!DOCTYPE html>',
    toolbar:
      'undo redo | image | bold italic underline | fontsizeselect | alignleft aligncenter alignright alignjustify | forecolor backcolor removeformat | emoticons | link',  // eslint-disable-line
    toolbar_sticky: true,
    contextmenu: 'link table',
    toolbar_mode: 'sliding',
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
    paste_data_images: true,
    language: 'pt_BR',
    fontsize_formats: '12px 14px 16px 20px 23px 30px 35px',
    content_style: 'body { font-size: 16px; }',
    content_css: 'body { font-size: 16px; }',
    automatic_uploads: true,
    image_title: false,
    image_description: false,
    file_picker_types: 'image',
    image_dimensions: false,
  },
  defaultLocale: 'pt',
};

export default AppConfig;