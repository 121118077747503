import { translate } from '~/services/i18n';

export enum TransactionPaymentType {
  CREDIT_CARD = 1,
  BANK_SLIP = 2,
  PIX = 3,
}

export const paymentTypeArray = [
  { value: TransactionPaymentType.CREDIT_CARD, i18n: 'PAGES.PANEL.OFFERS_RECEIVED.REPORT.ENUMS.CREDIT_CARD' },
  { value: TransactionPaymentType.BANK_SLIP, i18n: 'PAGES.PANEL.OFFERS_RECEIVED.REPORT.ENUMS.BANK_SLIP' },
  { value: TransactionPaymentType.PIX, i18n: 'PAGES.PANEL.OFFERS_RECEIVED.REPORT.ENUMS.PIX' },
];

export const getPaymentType = (value: number) => {
  let response = '';
  if (value) {
    const found = paymentTypeArray.find(o => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};