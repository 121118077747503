import { EventType } from "./typeEvent";

export enum ACTION_TYPES {
  INTERNAL_NAVIGATION = 1,
  EXTERNAL_NAVIGATION = 2,
}

export enum SCREEN_TYPE {
  EVENTS_DETAILS = 1,
  NEWS_DETAILS = 3,
  OFFERS_DETAILS = 4,
  COMMUNITY_DETAILS = 13,
}

export enum TO_SCREEN_TYPE {
  DAILY_PASSWORD = 2,
  EVENTS_LIST = 5,
  KNOW_IECLB = 6,
  MY_SYNOD = 7,
  OFFERS_LIST = 8,
  NEWS_LIST = 9,
  COMMUNITY_LIST = 12,
}

export const screenTypeArray = [
  {
    value: SCREEN_TYPE.EVENTS_DETAILS,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.EVENTS_DETAILS',
    eventType: EventType.EVENT,
  },
  {
    value: SCREEN_TYPE.NEWS_DETAILS,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.NEWS_DETAILS',
    eventType: EventType.NEWS,
  },
  {
    value: SCREEN_TYPE.OFFERS_DETAILS,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.OFFERS_DETAILS',
    eventType: EventType.OFFERING,
  },
  {
    value: SCREEN_TYPE.COMMUNITY_DETAILS,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.COMMUNITY_DETAILS',
    eventType: EventType.COMMUNITY,
  },
];

export const toScreenTypeArray = [
  {
    value: TO_SCREEN_TYPE.DAILY_PASSWORD,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.DAILY_PASSWORD',
  },
  {
    value: TO_SCREEN_TYPE.EVENTS_LIST,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.EVENTS_LIST',
  },
  {
    value: TO_SCREEN_TYPE.KNOW_IECLB,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.KNOW_IECLB',
  },
  {
    value: TO_SCREEN_TYPE.MY_SYNOD,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.MY_SYNOD',
  },
  {
    value: TO_SCREEN_TYPE.OFFERS_LIST,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.OFFERS_LIST',
  },
  {
    value: TO_SCREEN_TYPE.NEWS_LIST,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.NEWS_LIST',
  },
  {
    value: TO_SCREEN_TYPE.COMMUNITY_LIST,
    name: 'PAGES.PANEL.CALL_TO_ACTION.ENUMS.COMMUNITY_LIST',
  },
];
