import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import PDFSinodReport from '../PdfSinod/Report/PdfSinodReport';
import PDFSinodDetails from '../PdfSinod/Details/PdfOfferDetails';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const PDFNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ACTIVITIES', 'PDF_SINOD_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.TITLE_SINOD')}
          pageDescription={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.DESCRIPTION_SINOD')}
        >
        <PDFSinodReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('ACTIVITIES', 'PDF_SINOD_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.PAGE_SINOD_TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <PDFSinodDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default PDFNavigationStack;
