import React, { useState, useEffect } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as WorshipContentActions from '~/actions/worshipContent';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';

import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedFilter from '~/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';

import { translate } from '~/services/i18n';
import { useReduxState } from '~/hooks/useReduxState';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { WorshipContentType } from '@portal/enum/worship';

import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.IWorshipContentAdvancedFilter = {
  type: WorshipContentType.LITURGY,
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
};

const LiturgyReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);

  const { worshipContent } = useReduxState();

  const searchFields: utils.SearchParams[] = [
    {
      name: 'title',
      placeholder: 'Título',
      type: AdvancedFilterType.TEXT,
      defaultValue: '',
    },
    {
      name: 'content',
      placeholder: 'Subtítulo',
      type: AdvancedFilterType.TEXT,
      defaultValue: '',
    },
    {
      name: 'isDESC',
      placeholder: 'Recentes ou antigas',
      type: AdvancedFilterType.SELECT,
      defaultValue: isDescArray[0].value,
      options: isDescArray
    },
  ];

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
      type: WorshipContentType.LITURGY,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (filters: advancedFilterModels.IWorshipContentAdvancedFilter) => {
    dispatch(WorshipContentActions.getWorshipContentReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(WorshipContentActions.removeWorshipContent(id, 'LITURGY'));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
      type: WorshipContentType.LITURGY,
    });
  };

  return (
    <div className="report">
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate(
                'PAGES.PANEL.LITURGY.REPORT.PAGE_TITLE'
              )}
              pageDescription={translate(
                'PAGES.PANEL.LITURGY.REPORT.PAGE_DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6} className="text-right">
            <Link
              to={`${getRouteStackPath(
                'WORSHIP',
                'LITURGY_DETAILS'
              )}/${translate('SHARED.ADD_ID')}`}
            >
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <AdvancedFilter
              fields={searchFields}
              onSearch={(filtersData: any) => {
                onSearch({
                  ...filtersData, type: WorshipContentType.LITURGY,
                })
                setAdvancedFilters(filtersData);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={worshipContent?.list?.rows || []}
                rowCount={worshipContent?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.LITURGY.REPORT.TABLE.FIELDS.TITLE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.LITURGY.REPORT.TABLE.FIELDS.TITLE.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.LITURGY.REPORT.TABLE.FIELDS.CONTENT.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.LITURGY.REPORT.TABLE.FIELDS.CONTENT.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.LITURGY.REPORT.TABLE.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.LITURGY.REPORT.TABLE.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath(
                          'WORSHIP',
                          'LITURGY_DETAILS'
                        )}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    type: WorshipContentType.LITURGY,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LiturgyReport;
