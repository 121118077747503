import { translate } from '../services/i18n';

export const moneyFormat = (value: number): string => {
  let response = null;
  try {
    response = new Intl.NumberFormat(translate('SETTINGS.LANG'), {
      currency: translate('SETTINGS.MONEY'),
      style: 'currency',
    }).format(value);
  } catch (e: any) {
    throw Error(e);
  }

  return response;
};

export const phoneFormat = (value: string): string => {
  let match;
  if (value) { match = value.match(/^(\d{2})(\d{5})(\d{4})$/); }
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return 'Número Inválido';
};

export const removeSpecialChars = (str: string | null | undefined) => {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').trim() : '';
};

export const formatDateTime = (date: string | null | undefined, time: string | null | undefined) => {
  const splitedDate = date?.split('/');
  const splitedTime = time?.split(':');
  let formatedDate = null;

  if (splitedDate && splitedTime) {
    formatedDate = new Date(
      parseInt(splitedDate[2], 10),
      parseInt(splitedDate[1], 10) - 1,
      parseInt(splitedDate[0], 10),
      parseInt(splitedTime[0] + 3, 10),
      parseInt(splitedTime[1], 10),
      0);
  }

  return formatedDate;
};