import {
  CALL_TO_ACTION_DETAIL,
  CALL_TO_ACTION_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.CallToActionReducer = {
  detail: null,
  list: null,
};

const callToActionReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {

    case CALL_TO_ACTION_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    case CALL_TO_ACTION_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default callToActionReducer;
