import getInstance from './instance';
import { IAnnouncementAdvancedFilter } from '~/models/advanced-filters';

const BASE_URL: string = '/announcement';
const ABOUT_PASSWORD_BASE_URL: string = '/settings';

const AnnouncementApi = {
  getReport: async (
    params: advancedFilterModels.IAnnouncementAdvancedFilter
  ) => {
    const instance = await getInstance();
    const { data } = await instance.get(BASE_URL, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  create: async (params: models.AnnouncementApi) => {
    const instance = await getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  },

  update: async (id?: string, params?: any) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },

  getAllAnnouncements: async (params: IAnnouncementAdvancedFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/all`, { params });

    return data;
  },

  createAboutPassword: async (params: models.AboutDailyPasswordForm) => {
    const instance = getInstance();
    const { data } = await instance.post(`${ABOUT_PASSWORD_BASE_URL}/`, params );

    return data;
  },

  updateAboutPassword: async (params: models.AboutDailyPasswordForm, id: string) => {
    const instance = getInstance();
    const { data } = await instance.put(`${ABOUT_PASSWORD_BASE_URL}/${id}`, params );

    return data;
  },

  getAboutPassword: async (key: string) => {
    const params = {
      key
    };
    const instance = getInstance();
    const { data } = await instance.get(`${ABOUT_PASSWORD_BASE_URL}/key`, { params} );

    return data;
  },

};

export default AnnouncementApi;
