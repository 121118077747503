import getInstance from './instance';

const BASE_URL: string = 'event';

const EventApi = {
  createEvent: async (eventPayload: models.RequestSchedule) => {
    const instance = getInstance();
    const { data } = await instance.post(BASE_URL, eventPayload);

    return data;
  },

  getAll: async (params: any) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/all`, params);

    return data;
  },

};

export default EventApi;
