import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { WL_COMPANY_PANEL_LOGO } from '~/config/env';
import { getRouteStackPath, routeExist } from '~/config/routes';
import { ProfileType } from '~/enum/profileType';

import PanelSidebarMenu from '../PanelSidebarMenu/PanelSidebarMenu';
import { useReduxState } from '@portal/hooks/useReduxState';

interface IPanelSidebar {
  routes: models.route[];
}

const PanelSidebar = (props: IPanelSidebar) => {
  const location = useLocation();

  const { user } = useReduxState();

  const validatePath = () => {
    if (!routeExist(location.pathname)) {
      // window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    }
  };

  useEffect(() => {
    validatePath();
  }, []);

  return (
    <div className="panel-sidebar">
      <div className="panel-sidebar__logo">
        <Link to={getRouteStackPath('DASHBOARD', 'DETAILS')}>
          <img
            className="panel-sidebar__logo__img"
            src={WL_COMPANY_PANEL_LOGO}
            alt="sidebar logo"
          />
        </Link>
      </div>
      <div className="panel-sidebar__name">
        <span className="panel-sidebar__name__span">
          {user.me?.synod ? user.me?.synod.name : user.me?.user.name}
        </span>
      </div>
      <div className="panel-sidebar__menu">
        <PanelSidebarMenu
          access={(accessType: ProfileType) =>
            !user.me?.user?.profileType || user.me?.user?.profileType === accessType
          }
          routes={props.routes}
        />
      </div>
    </div>
  );
};

export default PanelSidebar;
