import {
  LeftOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import * as Transaction from '@portal/actions/transaction';
import { useReduxState } from '@portal/hooks/useReduxState';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { useLocation, useParams, Link } from 'react-router-dom';
import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { getRouteStackPath } from '@portal/config/routes';
import { getAllSynods } from '@portal/actions/synod';
import AdvancedInput from '@portal/components/AdvancedInput/AdvancedInput';
import { DateTime } from 'luxon';
import { getPaymentType } from '@portal/enum/transactionType';

const initialValues: models.Transaction = {
  externalTransactionId: '',
  bankSlipUrl: '',
  pixQrCodeUrl: '',
  pixQrCodeText: '',
  value: '',
  status: null,
  paymentType: null,
  numberOfInstallments: null,
  paymentCapturedAt: null,
  userId: '',
  eventId: '',
  subAccountId: '',
  synodId: '',
  event: {
    name: '',
    title: '',
  },
};

const OffersDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const dispatch = useDispatch();
  const { transaction } = useReduxState();

  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getAllSynods());
  }, []);

  useEffect(() => {
    if (transaction && transaction.detail) {
      setForm(transaction.detail);
    } else {
      setForm(initialValues);
    }
  }, [transaction.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(Transaction.getDetail(params.id));
    } else {
      dispatch(Transaction.cleanDetails());
    }
  }, [pathname, pageType]);

  return (
    <div className="schedule-details">
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate(
                    'PAGES.PANEL.OFFERS.TITLE'
                  ),
                  url: getRouteStackPath('OFFERS', 'OFFERS_RECEIVED_DETAILS'),
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.OFFERS_RECEIVED_DETAILS.REPORT.TITLE`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="details__title">
              <h3 className="details__title__text">
                {translate(
                  `PAGES.PANEL.OFFERS_RECEIVED_DETAILS.REPORT.TITLE`
                )}
              </h3>
              <p className="details__title__description">
                {translate(
                  `PAGES.PANEL.OFFERS_RECEIVED_DETAILS.REPORT.PAGE_DESCRIPTION`
                )}
              </p>
            </div>
            <AdvancedForm onSubmit={() => false}>
              <div className="details__form">
                <div className="details__title">
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) => false}
                        value={form.event?.title}
                        label={translate(
                          'PAGES.PANEL.OFFERS_RECEIVED_DETAILS.REPORT.FIELDS.EVENT_NAME'
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) => false}
                        value={`${translate('SHARED.CURRENCY_SYMBOL')} ${form.value}`}
                        label={translate(
                          'PAGES.PANEL.OFFERS_RECEIVED_DETAILS.REPORT.FIELDS.VALUE'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) => false}
                        value={DateTime.fromISO(form.createdAt as string).toLocaleString(
                          DateTime.DATE_SHORT
                        )}
                        label={translate(
                          'PAGES.PANEL.OFFERS_RECEIVED_DETAILS.REPORT.FIELDS.CREATED_AT'
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) => false}
                        value={getPaymentType(form.paymentType as number)}
                        label={translate(
                          'PAGES.PANEL.OFFERS_RECEIVED_DETAILS.REPORT.FIELDS.PAYMENT_TYPE'
                        )}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__form__actions">
                  <Row className="details__form__actions__row">
                    <Col md="auto">
                      <Link
                        to={getRouteStackPath('OFFERS', 'OFFERS_RECEIVED_REPORT')}
                      >
                        <AdvancedButton
                          variant="text"
                          text={translate('SHARED.BACK')}
                          startIcon={<LeftOutlined />}
                        />
                      </Link>
                    </Col>
                    <Col md="auto">
                      <AdvancedButton
                        type="submit"
                        text={translate('SHARED.SAVE')}
                        endIcon={<PlusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </AdvancedForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OffersDetails;
