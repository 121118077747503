import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';
import { renderOnlyTextFromHtml } from '../../../../utils/string'
import * as CampaignsActions from '~/actions/campaign';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { getActiveTypeTitle, getScope, getScopeTypeTitle, Scope } from '~/enum/typeEvent';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.ICampaignAdvancedFilter = {
  title: '',
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  type: 2,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'name',
    placeholder: 'Nome',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'scope',
    placeholder: 'Perfil',
    type: AdvancedFilterType.SELECT,
    defaultValue: '',
    options: [({ name: 'Geral', value: null }), ...getScope.map(o => ({ ...o, value: o.value }))]
  },
  {
    name: 'isActive',
    placeholder: 'Oferta ativa',
    type: AdvancedFilterType.SELECT,
    defaultValue: '',
    options: [({ name: 'Ativa', value: 'true' }), ({ name: 'Inativa', value: 'false' })]
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const CampaignsReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { campaigns, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    if (user.me) {
      const synodId = user.me?.synod?.id || user.me?.user?.synodId;
      onSearch({
        ...advancedFilters,
        ...filter,
        synodId,
        ...synodId && { scope: Scope.SYNODAL }
      });
    }
  }, [user]);

  const onSearch = (filters: advancedFilterModels.ICampaignAdvancedFilter) => {
    dispatch(CampaignsActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(CampaignsActions.remove(id));

    const filter = NavigationService.getQuery();
    const synodId = user.me?.synod?.id || user.me?.user?.synodId;
    onSearch({
      ...advancedFilters,
      ...filter,
      synodId,
      ...synodId && { scope: Scope.SYNODAL }
    });
  };

  return (
    <div className="report">
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate(
                'PAGES.PANEL.CAMPAIGNS.REPORT.PAGE_TITLE'
              )}
              pageDescription={translate(
                'PAGES.PANEL.CAMPAIGNS.REPORT.PAGE_DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6} className="text-right">
            <Link
              to={`${getRouteStackPath(
                'ACTIVITIES',
                'CAMPAIGNS_DETAILS'
              )}/${translate('SHARED.ADD_ID')}`}
            >
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <AdvancedFilter
              fields={searchFields}
              onSearch={(filtersData: any) => {
                const synodId = user.me?.synod?.id || user.me?.user?.synodId;
                onSearch({
                  ...filtersData,
                  synodId,
                  ...synodId && { scope: Scope.SYNODAL }
                });
                setAdvancedFilters(filtersData);
              }}
              defaultFields={initialValues}
            />
          </Col>
        </Row>
      </div>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={campaigns?.list?.rows || []}
                rowCount={campaigns?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.FIELD_TITLE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.TITLE'
                    ),
                    sortable: false,
                    hide: false,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.FIELD_DESCRIPTION'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.DESCRIPTION'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {renderOnlyTextFromHtml(o.row.description)}
                        </>
                      )
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.FIELD_TYPE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.TYPE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{o.row.synod && o.row.scope === Scope.SYNODAL ? o.row.synod.name : translate('ENUMERATORS.PROFILE_TYPE.ADMIN.LABEL')}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.FIELD_ACTIVE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CAMPAIGNS.REPORT.TABLE.ACTIVE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{getActiveTypeTitle(o.value as boolean)}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath(
                          'ACTIVITIES',
                          'CAMPAIGNS_DETAILS'
                        )}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const synodId = user.me?.synod?.id || user.me?.user?.synodId;
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId,
                    ...synodId && { scope: Scope.SYNODAL }
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CampaignsReport;
