import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import * as NewsActions from '~/actions/news';
import { getScope, Scope, TYPE_EVENT } from '@portal/enum/typeEvent';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getRouteStackPath } from '@portal/config/routes';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { isDescArray } from '@portal/enum/isDesc';
import { REPORT_PAGE_SIZE } from '@portal/config/env'
import moment from 'moment';

const initialValues: advancedFilterModels.INewsAdvancedFilter = {
  title: '',
  description: '',
  fileUrl: '',
  date: '',
  type: TYPE_EVENT.SINODAL,
  pageSize: REPORT_PAGE_SIZE,
  page: 1,
  offset: 0,
  orderBy: 'createdAt',
  isDESC:  isDescArray[0].value,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'title',
    placeholder: 'Título',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'scope',
    placeholder: 'Perfil',
    type: AdvancedFilterType.SELECT,
    defaultValue: '',
    options: [({ name: 'Geral', value: null }), ...getScope.map(o => ({ ...o, value: o.value }))]
  },
  {
    name: 'rangeStartsAt',
    placeholder: 'Data inicial',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
    maxRange: 30,
    format: translate('SHARED.FORMAT_DATE'),
  },
  {
    name: 'rangeEndsAt',
    placeholder: 'Data final',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
    maxRange: 30,
    format: translate('SHARED.FORMAT_DATE'),
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const NewsReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { news, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    const synodId = user.me?.synod?.id || user.me?.user?.synodId;
    if (user.me) {
      onSearch({
        ...advancedFilters,
        ...filter,
        synodId,
        ...synodId && { scope: Scope.SYNODAL }
      });
    }
  }, [user]);

  const onSearch = (filters: advancedFilterModels.INewsAdvancedFilter) => {
    dispatch(NewsActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(NewsActions.remove(id));
    const filter = NavigationService.getQuery();
    const synodId = user.me?.synod?.id || user.me?.user?.synodId;
    onSearch({
      ...advancedFilters,
      ...filter,
      synodId: user.me?.synod?.id,
      ...synodId && { scope: Scope.SYNODAL }
    });
  };

  return (
    <div className="report">
      <Row>
        <Col>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NEWS.REPORT.PAGE_TITLE')}
            pageDescription={translate('PAGES.PANEL.NEWS.REPORT.PAGE_DESCRIPTION')}
          />
        </Col>
        <Col lg={6} className="text-right">
          <Link
            to={`${getRouteStackPath('ACTIVITIES', 'NEWS_DETAILS')}/${translate(
              'SHARED.ADD_ID'
            )}`}
          >
            <AdvancedButton
              text={translate('SHARED.ADD_NEW')}
              endIcon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              const synodId = user.me?.synod?.id || user.me?.user?.synodId;
              onSearch({ 
                ...filtersData, 
                synodId, 
                ...synodId && { scope: Scope.SYNODAL } 
              })
              setAdvancedFilters(filtersData)
            }}
          />
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={news?.list?.rows || []}
                rowCount={news?.list?.count || 0}
                columns={[
                  {
                    field: translate('PAGES.PANEL.NEWS.FIELDS.ID.KEY'),
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.ID.TITLE'),
                    flex: 1,
                    sortable: false,
                    hide: true,
                  },
                  {
                    field: translate('PAGES.PANEL.NEWS.FIELDS.TITLE.KEY'),
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.TITLE.TITLE'),
                    flex: 1,
                    valueFormatter: (params) => params.row?.ad?.title,
                  },
                  {
                    field: translate('PAGES.PANEL.NEWS.FIELDS.SYNOD.KEY'),
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.SYNOD.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {o.row.synod && o.row.scope === Scope.SYNODAL ? o.row.synod.name : translate('ENUMERATORS.PROFILE_TYPE.ADMIN.LABEL')}
                        </>
                      )
                    }
                  },
                  {
                    field: translate('PAGES.PANEL.NEWS.FIELDS.CREATED_AT.KEY'),
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.CREATED_AT.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    align: 'center',
                    field: translate('PAGES.PANEL.NEWS.FIELDS.ACTIONS.KEY'),
                    headerAlign: 'center',
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.ACTIONS.TITLE'),
                    renderCell: (o: CellParams) => {
                      return (
                        <DataTableActions
                          row={o.row}
                          basePath={getRouteStackPath('ACTIVITIES', 'NEWS_DETAILS')}
                          onRemove={onRemove}
                        />
                      );
                    },
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const synodId = user.me?.synod?.id || user.me?.user?.synodId;
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    offset: filters.page - 1,
                    synodId,
                    ...synodId && { scope: Scope.SYNODAL }
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewsReport;
