import { KeyboardDateTimePicker, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

const AdvancedDateTimePicker = ({
  value,
  label,
  variant = 'outlined',
  onChange,
  time,
  hint,
  date,
  InputProps,
}: any) => {
  return (
    <div className="advanced-date-time-picker">
      {time && (
        <KeyboardTimePicker
          variant="inline"
          label={label}
          value={value}
          onChange={onChange}
          format="HH:mm"
          inputVariant={variant}
          autoOk
          ampm={false}
        />
      )}

      {(!time && !date) && (
        <KeyboardDateTimePicker
          variant="inline"
          ampm={false}
          label={label}
          value={value}
          onChange={onChange}
          format="dd/MM/yyyy HH:mm"
          inputVariant={variant}
          autoOk
          InputProps={InputProps}
        />
      )}

      {
        date &&
        < KeyboardDatePicker
          variant="inline"
          label={label}
          value={value}
          onChange={onChange}
          format="dd/MM/yyyy"
          inputVariant={variant}
          autoOk
          InputProps={InputProps}
        />
      }
      {hint}
    </div>
  );
};

export default AdvancedDateTimePicker;
