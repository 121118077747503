import MessagesRequests from '~/repositories/schedule';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { MESSAGES_DETAILS, MESSAGES_REPORT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: MESSAGES_DETAILS,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.IScheduleAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Schedule> = await MessagesRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: MESSAGES_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Schedule = await MessagesRequests.getDetail(id);

    dispatch({
      payload,
      type: MESSAGES_DETAILS,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.AddMessage) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await MessagesRequests.create(params as models.AddSchedule);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.NOTICE.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await MessagesRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.NOTICE.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await MessagesRequests.update(id, params);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.NOTICE.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
