import {
  CloudUploadOutlined,
  LeftOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import * as MessagesActions from '@portal/actions/messages';
import * as FileActions from '@portal/actions/file';
import { getAllSynods } from '@portal/actions/synod';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { EventType, Scope } from '@portal/enum/typeEvent';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as MessageService from '@portal/services/message';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import { FileSchedule } from '~/models/module';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { useLocation, useParams, Link } from 'react-router-dom';
import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { getRouteStackPath } from '@portal/config/routes';
import { DateTime } from 'luxon';
import { maskDate } from '@portal/services/masks';
import { ProfileType } from '@portal/enum/profileType';
import { FileType } from '@portal/enum/newsType';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import AdvancedCheckbox from '@portal/components/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';

const initialValues: models.AddMessage = {
  name: '',
  startsAtDate: '',
  startsAtTime: '00:00',
  endsAtDate: '',
  endsAtTime: '23:59',
  type: EventType.MESSAGE,
  title: '',
  description: '',
  synodId: null,
  mediaUrl: '',
  secondaryMediaUrl: '',
  scope: Scope.SYNODAL,
  media: null,
  files: [],
};

const MessagesDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const dispatch = useDispatch();
  const [synodCheckBox, setSynodCheckBox] = useState(false);
  const [file, setFile] = useState<FileSchedule | null>();
  const [fileThumbnail, setFileThumbnail] = useState<FileSchedule | null>();
  const { synod, user, messages } = useReduxState();

  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const onFormChange = (
    key: string,
    value: string | null | number | undefined
  ) => {
    setForm((prevState: models.AddMessage) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(getAllSynods());
  }, []);

  useEffect(() => {
    if (messages && messages.detail) {
      setForm(messages.detail);
      if (messages.detail.startsAtDate) {
        onFormChange(
          'startsAtDate',
          DateTime.fromISO(messages.detail.startsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }

      if (messages.detail.endsAtDate) {
        onFormChange(
          'endsAtDate',
          DateTime.fromISO(messages.detail.endsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }


      if (messages.detail.synodId) {
        setSynodCheckBox(true);
      }
    } else {
      setForm(initialValues);
      setSynodCheckBox(false);
    }
  }, [messages.detail]);

  useEffect(() => {
    setSynodCheckBox(false);
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(MessagesActions.getDetail(params.id));
    } else {
      dispatch(MessagesActions.cleanDetails());
    }
  }, [pathname, pageType]);

  const setReportLinkYoutube = (linkYT: string) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = linkYT.match(regExp);
    let videoId = null;
    if (match && match[7].length === 11) {
      videoId = match[7];
    }
    if (linkYT.length > 24) {
      if (videoId === null) {
        return MessageService.error(
          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.ERRORS.SECONDARY_MEDIA_URL'
        );
      }
    }
    onFormChange('secondaryMediaUrl', linkYT);
  };

  const onFormSubmit = async () => {
    let mediaUrl;
    const fileList = [];

    if (
      !DateTime.fromFormat(form.startsAtDate as string, 'dd/mm/yyyy')
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate(
          'PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.ERRORS.INIT_DATE'
        )
      );
    }

    if (
      !DateTime.fromFormat(form.endsAtDate as string, 'dd/mm/yyyy')
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.ERRORS.END_DATE')
      );
    }

    if (form.media) {
      const { extension, name, base64 } = form.media;
      mediaUrl = await dispatch(FileActions.create(
        {
          type: 1,
          extension,
          name,
          base64,
          isPrivate: false,
        }
      ));
    }

    if (!form.startsAtDate || !form.endsAtDate) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.ERRORS.DATE')
      );
    }

    if (!form.title) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.ERRORS.TITLE')
      );
    }

    if (!form.name) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.ERRORS.NAME')
      );
    }

    if (!form.type) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.ERRORS.TYPE')
      );
    }

    if (!form.description) {
      return MessageService.error(
        translate(
          'PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.ERRORS.DESCRIPTION'
        )
      );
    }

    if (file) {
      fileList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            !item.file?.thumbnail)?.file?.id : null,
          name: file?.name || null,
          type: FileType.IMAGE,
          base64: file?.base64 || null,
          extension: file?.extension || null,
          isPrivate: false,
        }
      );
    }

    if (fileThumbnail) {
      fileList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            item.file?.thumbnail)?.file?.id : null,
          name: fileThumbnail?.name || null,
          type: FileType.IMAGE,
          base64: fileThumbnail?.base64 || null,
          extension: fileThumbnail?.extension || null,
          thumbnail: true,
          isPrivate: false,
        }
      );
    }

    const data: models.AddMessage = {
      name: form.name,
      type: form.type,
      title: form.title,
      description: form.description,
      mediaUrl,
      secondaryMediaUrl: form.secondaryMediaUrl,
      scope: synodCheckBox ? Scope.SYNODAL : Scope.NATIONAL,
      startsAtDate: DateTime.fromFormat(
        form.startsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      startsAtTime:
        form.startsAtTime &&
        DateTime.fromFormat(form.startsAtTime as string, 'hh:mm').toISOTime(),
      endsAtDate: DateTime.fromFormat(
        form.endsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      endsAtTime:
        form.endsAtTime &&
        DateTime.fromFormat(form.endsAtTime as string, 'hh:mm').toISOTime(),
      synodId: synodCheckBox ? form.synodId || user.me?.synod?.id : null,
      files: fileList,
    };

    if (form.type !== EventType.MESSAGE) {
      delete data.files;
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(MessagesActions.update(params.id, data));
    } else {
      if (user.me?.user.profileType === ProfileType.SYNOD_ADMIN) {
        dispatch(
          MessagesActions.create({
            ...data,
            synodId: user.me?.synod?.id,
            scope: Scope.SYNODAL,
          })
        );
      } else {
        dispatch(
          MessagesActions.create({
            ...data
          })
        );
      }
    }
  };

  return (
    <div className="schedule-details">
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate('PAGES.PANEL.MESSAGES.REPORT.PAGE_TITLE'),
                  url: getRouteStackPath('ACTIVITIES', 'MESSAGES_REPORT'),
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.MESSAGES.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                    }`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="details__title">
              <h3 className="details__title__text">
                {translate(
                  `PAGES.PANEL.MESSAGES.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </h3>
              <p className="details__title__description">
                {translate(
                  `PAGES.PANEL.MESSAGES.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </p>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <div className="details__form">
                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.TYPE_MESSAGE'
                    )}
                  </p>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('title', value)
                        }
                        value={form.title}
                        label={translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.TITLE'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.NAME_MESSAGE'
                        )}
                        value={form.name}
                        onChange={(val: string) => onFormChange('name', val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) => {
                          onFormChange('startsAtDate', value);
                          onFormChange('endsAtDate', value);
                        }}
                        value={maskDate(form && form.startsAtDate)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.MESSAGES.DETAILS.PLACEHOLDER_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.DATE'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.SECONDARY_MEDIA_URL'
                        )}
                        value={form.secondaryMediaUrl}
                        onChange={(val: string) => {
                          onFormChange('mediaUrl', val);
                          setReportLinkYoutube(val);
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                <Row>
                  {form.mediaUrl && (
                    <Col md={6}>
                      <AdvancedInput
                        disabled={true}
                        value={form && form.mediaUrl}
                        label={translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.MEDIA_URL'
                        )}
                      />
                    </Col>
                  )}
                </Row>

                {form.type !== EventType.REMINDER && (
                  <Row>
                    <Col md={4}>
                      <label>
                        {translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.MEDIA'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={(value: string) =>
                          onFormChange('media', value)
                        }
                        value={form.mediaUrl}
                        mp3
                      >
                        <AdvancedButton
                          text={translate(
                            'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD_MEDIA'
                          )}
                          endIcon={<CloudUploadOutlined />}
                        />
                      </AdvancedUploader>
                    </Col>
                    <Col md={4}>
                      <label>
                        {translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.UPLOAD_THUMB'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFileThumbnail}
                        thumb
                        defaultImage={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                    <Col md={4}>
                      <label>
                        {translate(
                          'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.UPLOAD'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFile}
                        defaultImage={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                  </Row>
                )}
                
                <div className="details__title">
                  {user.me?.user.profileType === ProfileType.ADMIN && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <AdvancedCheckbox
                            label={translate(
                              'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.ADD_SYNOD'
                            )}
                            value={synodCheckBox}
                            onChange={() => setSynodCheckBox(!synodCheckBox)}
                          />
                        </Col>
                        <Col md={6}>
                          {synodCheckBox && (
                            <AdvancedSelect
                              label={translate(
                                'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.SYNOD'
                              )}
                              value={form.synodId}
                              onChange={(val: string | undefined) =>
                                onFormChange('synodId', val)
                              }
                              options={synod.allSynod}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.DESCRIPTION'
                    )}
                  </p>
                  <Row>
                    <Col md={12}>
                      <AdvancedTextInput
                        id="messages-form"
                        value={form.description}
                        onChange={(val: string) => onFormChange('description', val)}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__form__actions">
                  <Row className="details__form__actions__row">
                    <Col md="auto">
                      <Link
                        to={getRouteStackPath('ACTIVITIES', 'MESSAGES_REPORT')}
                      >
                        <AdvancedButton
                          variant="text"
                          text={translate('SHARED.BACK')}
                          startIcon={<LeftOutlined />}
                        />
                      </Link>
                    </Col>
                    <Col md="auto">
                      <AdvancedButton
                        type="submit"
                        text={translate('SHARED.SAVE')}
                        endIcon={<PlusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </AdvancedForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MessagesDetails;
