import { getRouteStackPath } from '@portal/config/routes';
import { getMe } from '~/actions/user';
import AuthRequests from '~/repositories/auth';
import { setAuthorizationHeader } from '~/repositories/instance';
import * as MessageService from '~/services/message';
import * as StorageService from '~/services/storage';

import {
  AUTH_CHECK_LOGGED,
  AUTH_LOGIN,
  AUTH_LOGOUT,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const authenticate = (userData: models.AuthRequest) => async (
  dispatch: any
) => {
  dispatch(increaseLoading());
  try {
    const payload: models.AuthResponse = await AuthRequests.login(userData);

    StorageService.removeItem('session-token');

    StorageService.setItem('session-token', payload);

    setAuthorizationHeader(payload.token as string);

    dispatch({
      payload,
      type: AUTH_LOGIN,
    });

    StorageService.setItem('auth', userData);
    MessageService.success('PAGES.AUTH.LOGIN.MESSAGES.WELCOME');

    window.location.href = getRouteStackPath('DASHBOARD', 'DETAILS');
    dispatch(getMe());
  } catch (err: any) {
    if (err && err.response) {
      MessageService.error(err.response.message);
    } else if (err && err.message) {
      MessageService.error('PAGES.AUTH.LOGIN.MESSAGES.INVALID');
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const refreshToken = (userData: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.AuthResponse = await AuthRequests.refreshToken(userData);
    StorageService.setItem('session-token', payload);
    setAuthorizationHeader(payload.accessToken as string);

    dispatch({
      payload,
      type: AUTH_LOGIN,
    });

    dispatch(getMe());
  } catch (err: any) {
    StorageService.removeItem('session-token');
    window.location.href = '/';
  } finally {
    dispatch(decreaseLoading());
  }
};

export const logout = () => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    StorageService.removeItem('session-token');

    dispatch({
      type: AUTH_LOGOUT,
    });

    window.location.href = '/';
  } catch (err: any) {
    MessageService.error('APPLICATION.ERRORS.GENERIC');
  } finally {
    dispatch(decreaseLoading());
  }
};

export const checkIsLogged = () => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const token = StorageService.getItem('session-token');
    if (token) {
      setAuthorizationHeader(token.accessToken as string);
      dispatch({
        payload: token,
        type: AUTH_LOGIN,
      });
    }
  } finally {
    dispatch({
      type: AUTH_CHECK_LOGGED,
    });

    dispatch(decreaseLoading());
  }
};
