import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useState } from 'react';

import { compressImages } from '@portal/utils/imageUtils';
import { getBase64 } from '@portal/utils/string';
import * as MessageService from '~/services/message';
import { useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';

import { translate } from '~/services/i18n';

interface IAdvancedUploader {
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
  multiple?: boolean;
  onPreview?: (file: UploadFile<TemplateStringsArray>) => void;
  defaultImage?: any;
  className?: string;
  uploadClassName?: string;
  type?: 'image' | string;
  onChange: (file: any) => void;
  value?: any;
  hidePreview?: boolean;
  mp3?: boolean;
  isPdf?: boolean;
  fileList?: Array<UploadFile<any>>;
  thumb?: boolean;
}

const AdvancedUploader = ({
  disabled,
  children,
  multiple,
  onPreview,
  defaultImage,
  className,
  uploadClassName,
  type,
  onChange,
  value,
  hidePreview,
  mp3,
  isPdf,
  fileList,
  thumb,
}: IAdvancedUploader) => {
  const [filePreview, setFilePreview] = useState(value);

  useEffect(() => {
    if (value !== null) {
      setFilePreview(value);
    }
  }, [value]);

  // tslint:disable-next-line:no-shadowed-variable
  const onFileChange = async ({ file }: any) => {
    if (file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        (mp3 && file.type === 'audio/mpeg') ||
        (isPdf && file.type === 'application/pdf');

      if (mp3 && file.type !== 'audio/mpeg') {
        return MessageService.error('COMPONENTS.ADVANCED_FILE_UPLOAD.ERROR_MP3');
      }

      if (!isJpgOrPng && type !== 'image') {
        return MessageService.error('COMPONENTS.ADVANCED_FILE_UPLOAD.ERROR');
      }
      if (type === 'image') {
        file.base64 = await compressImages([file]);
      } else {
        file.base64 = await getBase64(file);
      }

      file.extension = file.name.split('.').pop();
      onChange(file);

      toggleFilePreview(file ? file.base64 : value || null);
    } else {
      onChange(null);
    }
  };

  // tslint:disable-next-line:no-shadowed-variable
  const toggleFilePreview = (file: any) => {
    setFilePreview(file);
  };

  return (
    <div className={className || 'advanced-file-upload'}>
      <Upload
        accept={mp3 ? "audio/*" : undefined}
        className={uploadClassName || 'advanced-file-upload__upload'}
        customRequest={onFileChange}
        disabled={disabled}
        fileList={fileList}
        multiple={multiple}
        onChange={onChange}
        onPreview={onPreview || (() => true)}
      >
        {(mp3 && filePreview) && (
          <div className={"advanced-file-upload__preview-mp3"}>
            <ReactAudioPlayer
              src={filePreview as string}
              controls
            />
          </div>
        )}

        {(filePreview || defaultImage) && !hidePreview && !mp3 && (
          <div className={thumb ? "advanced-file-upload__preview-thumb" : "advanced-file-upload__preview-detail"}>
            <img
              className={thumb ? "advanced-file-upload__preview-thumb__img" : "advanced-file-upload__preview-detail__img"}
              src={defaultImage ?? filePreview as string}
              alt={defaultImage || filePreview ? translate('SHARED.PREVIEW_IMAGE_UPLOADER') : translate('SHARED.PREVIEW_IMAGE')}
            />
          </div>
        )}
        {children}
      </Upload>
    </div>
  );
};

export default AdvancedUploader;
