import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ProfileType } from '../../../../enum/profileType'
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';

import * as LeadersActions from '~/actions/leaders';

import { getRouteStackPath } from '~/config/routes';

import { getPagetType } from '~/utils/page';

import { PAGE_TYPE } from '~/enum/pageType';

import { useReduxState } from '~/hooks/useReduxState';
import AdvancedRadio from '~/components/AdvancedRadio/AdvancedRadio';

import { LeaderRole } from '~/enum/leaderRoleType';
import AdvancedSelect from '~/components/AdvancedSelect/AdvancedSelect';
import * as SynodActions from '~/actions/synod';
import * as ParishActions from '~/actions/parish';
import AdvancedCheckbox from '@portal/components/AdvancedCheckbox/AdvancedCheckbox';

const formInitialValues: models.Leader = {
  id: '',
  name: '',
  roleType: LeaderRole.MINISTER,
  roleDescription: '',
  parishId: null,
  synodId: null,
};

const LeadersDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [camEnabled, setCamEnabled] = useState(false);
  const [pageType] = useState(getPagetType());
  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { leader, parish, user, synod } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.Leader) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (leader && leader.detail) {
      setForm(leader.detail);
    } else {
      setForm(formInitialValues);
    }

    if(leader.detail?.roleDescription) {
      setCamEnabled(true);
    }

  }, [leader.detail]);

  useEffect(() => {
    if (form.parishId) {
      dispatch(ParishActions.getDetail(form.parishId));
    }
  }, [form.parishId]);

  useEffect(() => {
    dispatch(SynodActions.getAllSynods());
    dispatch(ParishActions.getAll(user.me?.synod?.id));

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(LeadersActions.getDetail(params.id));
    } else {
      dispatch(LeadersActions.cleanDetails());
    }
  }, [pathname, pageType]);

  const onFormSubmit = () => {

    if (!form.name) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NAME');
    }

    if (!camEnabled && (!form.parishId || !parish.detail?.id)) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_PARISH_ID');
    }

    const payload: any = {
      name: form.name,
      roleType: form.roleType,
      roleDescription: form.roleDescription,
      parishId: form.roleType !==  LeaderRole.MINISTER_RETIRED ? (form.parishId || parish.detail?.id) : null,
      synodId: form.synodId || parish.detail?.synodId || user.me?.synod?.id,
    };

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(LeadersActions.update(leader?.detail?.id, payload));
    } else {
      dispatch(LeadersActions.create(payload));
    }
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.USER.LEADERS.REPORT.TITLE'),
                url: getRouteStackPath('USER', 'LEADER_REPORT'),
                active: true,
              },
              {
                title: translate(
                  `PAGES.PANEL.USER.LEADERS.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                ),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.USER.LEADERS.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.USER.LEADERS.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={6}>
                  <AdvancedCheckbox
                    label={translate(
                      'PAGES.PANEL.COMMUNITY.DETAILS.FORM.ADD_CAM.LABEL'
                    )}
                    value={camEnabled}
                    onChange={() => setCamEnabled(!camEnabled)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.LEADERS.DETAILS.FORM.FIELDS.NAME.LABEL'
                    )}
                    value={form.name}
                    onChange={(val: string) => onFormChange('name', val)}
                  />
                </Col>
              </Row>
              <div>
                {
                  (!camEnabled || user.me?.user?.profileType === ProfileType.ADMIN) ? (
                    <>
                      {form.roleType !==  LeaderRole.MINISTER_RETIRED ? (
                        <Row>
                          <Col md={6}>
                            <AdvancedSelect
                              label={
                                !(pageType === PAGE_TYPE.EDIT) &&
                                translate(
                                  'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.PARISH.LABEL'
                                )
                              }
                              value={form.parishId}
                              onChange={(val: string) => onFormChange('parishId', val)}
                              options={parish.all}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <div>
                          {!user.me?.synod?.id && (
                            <Row>
                              <Col md={6}>
                                <AdvancedSelect
                                  label={
                                    !(pageType === PAGE_TYPE.EDIT) &&
                                    translate('PAGES.PANEL.USER.LEADERS.DETAILS.FORM.FIELDS.SYNOD_ID.LABEL')
                                  }
                                  value={form.synodId || ''}
                                  onChange={(val: string) => onFormChange('synodId', val)}
                                  options={synod.allSynod}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <Row>
                      {form.roleType !==  LeaderRole.MINISTER_RETIRED && (
                        <Col md={6}>
                          <AdvancedSelect
                            label={
                              !(pageType === PAGE_TYPE.EDIT) &&
                              translate(
                                'PAGES.PANEL.USER.LEADERS.DETAILS.FORM.FIELDS.SYNOD_ID.LABEL'
                              )
                            }
                            value={form.synodId || ''}
                            onChange={(val: string) => onFormChange('synodId', val)}
                            options={synod.allSynod}
                          />
                        </Col>
                      )}
                    </Row>                  
                  )
                }  
                <Row>
                  <Col md={6}>
                    {camEnabled && (
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.USER.LEADERS.DETAILS.FORM.FIELDS.CAM.LABEL'
                        )}
                        value={form.roleDescription || ''}
                        onChange={(val: string | undefined) =>
                          onFormChange('roleDescription', val)
                        }
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <AdvancedRadio
                      onChange={(val: number) => onFormChange('roleType', val)}
                      label={translate(
                        'PAGES.PANEL.USER.LEADERS.DETAILS.FORM.FIELDS.LEADER_ROLE.LABEL'
                      )}
                      value={form.roleType}
                      items={[
                        {
                          label: translate(
                            'ENUMERATORS.LEADER_ROLE.MINISTER.LABEL'
                          ),
                          value: LeaderRole.MINISTER,
                        },
                        {
                          label: translate('ENUMERATORS.LEADER_ROLE.PRESIDENT.LABEL'),
                          value: LeaderRole.PRESIDENT,
                        },
                        {
                          label: translate('ENUMERATORS.LEADER_ROLE.EMERITUS.LABEL'),
                          value: LeaderRole.MINISTER_RETIRED,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
              <div className="details__form__actions">
                <Row className="details__form__actions__row">
                  <Col md="auto">
                    <Link to={getRouteStackPath('USER', 'LEADER_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md="auto">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default LeadersDetails;
