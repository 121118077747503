import { ViaCep } from '~/repositories/utils';
import * as MessageService from '~/services/message';

import { GET_CEP } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanCepDetails = () => async (dispatch: any) => {
  dispatch({
    payload: {
      data: {
        bairro: null,
        cep: null,
        localidade: null,
        logradouro: null,
        uf: null,
        complemento: null,
      }
    },
    type: GET_CEP,
  });
};

export const getAddressByCep = (cep: string, callback?: (check: any) => void) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const data = await ViaCep.getAddressByCep(cep);
    if (data) {
      dispatch({
        type: GET_CEP,
        payload: data,
      });

      if (callback && typeof callback === 'function') {
        callback(data);
      }
    }
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
