import ParishRequests from '~/repositories/parish';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import {
  PARISH_REPORT,
  PARISH_DETAIL,
  GET_CEP,
  PARISH_ALL,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: PARISH_DETAIL,
  });
  dispatch({
    payload: {
      data: null,
    },
    type: GET_CEP,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.IParishAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Parish> = await ParishRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: PARISH_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Parish = await ParishRequests.getDetail(id);

    dispatch({
      payload,
      type: PARISH_DETAIL,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.Parish) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await ParishRequests.create(params);
    MessageService.success(
      'PAGES.PANEL.USER.PARISH.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await ParishRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.USER.PARISH.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await ParishRequests.update(id, params);
    MessageService.success(
      'PAGES.PANEL.USER.PARISH.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getAll = (synodId?: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: any = await ParishRequests.getAll(synodId);

    dispatch({
      payload,
      type: PARISH_ALL,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
