import * as MessageService from '~/services/message';

import { decreaseLoading, increaseLoading } from './loading';
import FileRequests from '~/repositories/file';

export const create = (
  params: models.CreateFile,
  callback?: (file: models.FileItem) => void
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload = await FileRequests.create(params);
    if (callback) {
      callback(payload);
    }

    return payload.url;
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = async (
  id: string,
) => {
  try {
    const payload = await FileRequests.delete(id);

    return payload;
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
  }
};
