import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import PasswordsDetails from './Details/PasswordsDetails';
import PasswordsReport from './Report/PasswordsReport';
const PasswordsNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ACTIVITIES', 'PASSWORDS_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PASSWORDS.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.PASSWORDS.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <PasswordsReport />
        </PanelContent>
      </Route>

      <Route
        path={`${getRouteStackPath('ACTIVITIES', 'PASSWORDS_DETAILS')}/:id`}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PASSWORDS.DETAILS.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.PASSWORDS.DETAILS.PAGE_DESCRIPTION'
          )}
        >
          <PasswordsDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default PasswordsNavigationStack;
