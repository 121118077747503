import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';
import { renderOnlyTextFromHtml } from '../../../../utils/string';
import * as OffersActions from '~/actions/offers';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { EventType, getActiveAtMomentTypeTitle, getScopeTypeTitle, Scope } from '~/enum/typeEvent';
import { DateTime } from 'luxon';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.IOfferAdvancedFilter = {
  title: '',
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  type: EventType.OFFERING,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'title',
    placeholder: 'Título',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'startsAtDate',
    placeholder: 'Data do evento',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
    maxRange: 30,
    format: translate('SHARED.FORMAT_DATE'),
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const OffersReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { offers, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    if (user.me) {
      const synodId = user.me?.synod?.id;
      onSearch({
        ...advancedFilters,
        ...filter,
        synodId,
        type: EventType.OFFERING,
        ...synodId && { scope: Scope.SYNODAL }
      });
    }
  }, [user]);

  const onSearch = (filters: advancedFilterModels.IOfferAdvancedFilter) => {
    dispatch(OffersActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(OffersActions.remove(id));

    const filter = NavigationService.getQuery();
    const synodId = user.me?.synod?.id || user.me?.user?.synodId;
    onSearch({
      ...advancedFilters,
      ...filter,
      synodId,
      type: EventType.OFFERING,
      ...synodId && { scope: Scope.SYNODAL }
    });
  };

  return (
    <div className="report">
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate(
                'PAGES.PANEL.OFFERS.REPORT.TITLE'
              )}
              pageDescription={translate(
                'PAGES.PANEL.OFFERS.REPORT.DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6} className="text-right">
            <Link
              to={`${getRouteStackPath(
                'OFFERS',
                'OFFERS_DETAILS'
              )}/${translate('SHARED.ADD_ID')}`}
            >
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              const synodId = user.me?.synod?.id || user.me?.user?.synodId;
              onSearch({
                ...filtersData,
                synodId,
                type: EventType.OFFERING,
                ...synodId && { scope: Scope.SYNODAL }
              });
              setAdvancedFilters(filtersData);
            }
            }
            defaultFields={initialValues}
          />
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={offers?.list?.rows || []}
                rowCount={offers?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.FIELD_TITLE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.TITLE'
                    ),
                    sortable: false,
                    hide: false,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.FIELD_DESCRIPTION'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.DESCRIPTION'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {renderOnlyTextFromHtml(o.row.description)} 
                        </>
                      )
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.FIELD_TYPE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.TYPE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{o.row.synod && o.row.scope === Scope.SYNODAL ? o.row.synod.name : getScopeTypeTitle(o.value as number)}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.FIELD_ACTIVE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.ACTIVE'
                    ),
                    flex: 0.7,
                    renderCell: (o: CellParams) => {
                      return <>{getActiveAtMomentTypeTitle(o.row.startsAtDate, o.row.endsAtDate)}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.FIELD_DATA_BEGIN'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.DATA_BEGIN'
                    ),
                    flex: 0.7,
                    renderCell: (o: CellParams | any) => {
                      return <>
                        {o.value ? DateTime.fromISO(o.value as string).toLocaleString(
                          DateTime.DATE_SHORT
                        ) : null}
                      </>;
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.FIELD_DATA_END'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS.REPORT.TABLE.DATA_END'
                    ),
                    flex: 0.7,
                    renderCell: (o: CellParams | any) => {
                      return <>
                        {o.value ? DateTime.fromISO(o.value as string).toLocaleString(
                          DateTime.DATE_SHORT
                        ) : null}
                      </>;
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath(
                          'OFFERS',
                          'OFFERS_DETAILS'
                        )}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const synodId = user.me?.synod?.id || user.me?.user?.synodId;
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId,
                    type: EventType.OFFERING,
                    ...synodId && { scope: Scope.SYNODAL }
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OffersReport;
