import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '~/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import CallToActionDetails from './Details/CallToActionDetails';
import CallToActionReport from './Report/CallToActionReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const CallToActionNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ACTIVITIES', 'CALLTOACTION_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.CALL_TO_ACTION.REPORT.PAGE_TITLE')}
          pageDescription={translate('PAGES.PANEL.CALL_TO_ACTION.REPORT.PAGE_DESCRIPTION')}
        >
          <CallToActionReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('ACTIVITIES', 'CALLTOACTION_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.CALL_TO_ACTION.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.CALL_TO_ACTION.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <CallToActionDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default CallToActionNavigationStack;
