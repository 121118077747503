import { API_URL } from '@portal/config/env';

import getInstance from './instance';

const WorshipApi = {
  getReport: async (params: any) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${API_URL}/worship`, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${API_URL}/worship/${id}`);

    return data;
  },

  add: async (params: models.Worship) => {
    const instance = await getInstance();
    const { data } = await instance.post(`${API_URL}/worship`, params);

    return data;
  },

  update: async (id: string, params: models.Worship) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${API_URL}/worship/${id}`, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${API_URL}/worship/${id}`);

    return data;
  }
};

export default WorshipApi;