import { API_URL } from '@portal/config/env';

import getInstance from './instance';

const WorshipContentApi = {
  getReport: async (params: any) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${API_URL}/worship-content`, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${API_URL}/worship-content/${id}`);

    return data;
  },

  add: async (params: models.WorshipContent) => {
    const instance = await getInstance();
    const { data } = await instance.post(`${API_URL}/worship-content`, params);

    return data;
  },

  update: async (id: string, params: models.WorshipContent) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${API_URL}/worship-content/${id}`, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${API_URL}/worship-content/${id}`);

    return data;
  }
};

export default WorshipContentApi;