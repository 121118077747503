import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  UploadOutlined,
  PlusCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedUploader from '~/components/AdvancedUploader/AdvancedUploader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import * as AnnouncementActions from '~/actions/announcement';
import * as FileActions from '~/actions/file';
import * as MessageService from '~/services/message';
import { maskDateTime } from '~/services/masks';
import { translate } from '~/services/i18n';
import { getRouteStackPath } from '~/config/routes';
import { useEffect } from 'react';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '@portal/enum/pageType';

const formInitialValues: models.Announcement = {
  title: '',
  file: null,
  shareFile: null,
  description: '',
  descriptionDailyMeditation: null,
  date: '',
  type: 1,
  shareUrl: null,
  fileUrl: null,
  secondFileUrl: null,
  mediaExternalUrl: null,
};

const PasswordsDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [pageType] = useState(getPagetType());
  const { pathname } = useLocation();
  const params = useParams() as { id: string };

  const dispatch = useDispatch();
  const { announcement } = useReduxState();

  const onFormChange = (key: string, value: any) => {
    setForm((prevState: models.Announcement) => ({
      ...prevState,
      [key]: value,
    }));

    if (key === 'file' && !value.fileList) {
      MessageService.success(
        translate('PAGES.PANEL.PASSWORDS.MESSAGES.FILE_SUCCESS')
      );
    }
  };

  const onFormSubmit = async () => {

    if(!form.description) {
      return MessageService.error(translate('PAGES.PANEL.PASSWORDS.MESSAGES.NO_DESCRIPTION'));
    }
    
    if(!form.title) {
      return MessageService.error(translate('PAGES.PANEL.PASSWORDS.MESSAGES.NO_TITLE'));
    }

    if (!form.date) {
      return MessageService.error(translate('PAGES.PANEL.PASSWORDS.MESSAGES.INVALID_DATE'));
    }

    let fileUrl = form.fileUrl;
    let secondFileUrl = form.secondFileUrl;
    let shareUrl = form.shareUrl;

    if (form.file) {
      const { extension, name, base64 } = form.file;
      fileUrl = await dispatch(FileActions.create(
        {
          type: 1,
          extension,
          name,
          base64,
          isPrivate: false,
        }
      ));
    }

    if (form.file2) {
      const { extension, name, base64 } = form.file2;
      secondFileUrl = await dispatch(FileActions.create(
        {
          type: 1,
          extension,
          name,
          base64,
          isPrivate: false,
        }
      ));
    }

    if (form.shareFile) {
      const { extension, name, base64 } = form.shareFile;
      shareUrl = await dispatch(FileActions.create(
        {
          type: 1,
          extension,
          name,
          base64,
          isPrivate: false,
        }
      ));
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(
        AnnouncementActions.update(announcement.detail?.id, {
          title: form.title,
          description: form.description,
          type: form.type,
          fileUrl,
          secondFileUrl,
          shareUrl,
          date: DateTime.fromFormat(form.date as string, translate('SHARED.FORMAT_DATE')).toISO(),
          mediaExternalUrl: form.mediaExternalUrl,
          descriptionDailyMeditation: form.descriptionDailyMeditation
        })
      );
    } else {
      dispatch(
        AnnouncementActions.create({
          title: form.title,
          description: form.description,
          type: form.type,
          fileUrl,
          secondFileUrl,
          shareUrl,
          date: DateTime.fromFormat(form.date as string, translate('SHARED.FORMAT_DATE')).toISO(),
          mediaExternalUrl: form.mediaExternalUrl,
          descriptionDailyMeditation: form.descriptionDailyMeditation
        })
      );
    }
  };

  useEffect(() => {
    if (announcement && announcement.detail) {
      setForm(announcement.detail);
      onFormChange('date', DateTime.fromISO(announcement.detail.date as string).toLocaleString(DateTime.DATE_SHORT));
    } else {
      setForm(formInitialValues);
    }
  }, [announcement.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(AnnouncementActions.getDetail(params.id));
    } else {
      setForm(formInitialValues);
      dispatch(AnnouncementActions.cleanDetails());
    }
  }, [pathname, pageType]);

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.PASSWORDS.TITLE'),
                url: getRouteStackPath('ACTIVITIES', 'PASSWORDS_REPORT'),
                active: true,
              },
              {
                title: translate('PAGES.PANEL.PASSWORDS.REPORT.BUTTON_LINK'),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate('PAGES.PANEL.PASSWORDS.TITLE')}
            </h3>
            <p className="details__title__description">
              {translate('PAGES.PANEL.PASSWORDS.REPORT.DESCRIPTION')}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.PASSWORDS.LABELS.TITLE')}
                    value={form.title}
                    onChange={(val: string) => onFormChange('title', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.PASSWORDS.LABELS.MEDIA_EXTERNAL_URL')}
                    value={form.mediaExternalUrl}
                    onChange={(val: string) => onFormChange('mediaExternalUrl', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="details__title">
                    <p className="details__title__description">
                      {translate(
                        'PAGES.PANEL.PASSWORDS.LABELS.DESCRIPTION'
                      )}
                    </p>
                    <Row>
                      <Col md={12}>
                        <AdvancedTextInput
                          id="description-form"
                          value={form.description}
                          onChange={(val: string) => onFormChange('description', val)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.PASSWORDS.LABELS.DATE')}
                    value={maskDateTime(form.date)}
                    onChange={(value: string) => onFormChange('date', value)}
                    placeholder={translate('PAGES.PANEL.PASSWORDS_ADD.REPORT.ADD.LABELS.DATE_PLACEHOLDER')}
                  />
                </Col>
              </Row>
              
              <Row>
                <Col md={3}>
                  <div className="details__title">
                    <p className="details__title__description">
                      {translate(
                        'Inclua aqui o áudio da senha diária'
                      )}
                    </p>
                    <Row>
                      <Col>
                        <AdvancedUploader
                          onChange={(value: string) => onFormChange('file', value)}
                          value={form.fileUrl}
                          hidePreview
                          mp3
                        >
                          <AdvancedButton
                            text={translate('PAGES.PANEL.PASSWORDS.LABELS.UPLOAD_DAILY_PASSWORD')}
                            endIcon={<UploadOutlined />}
                          />
                        </AdvancedUploader>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="details__title">
                    <p className="details__title__description">
                      {translate('Inclua aqui a imagem da senha diária')}
                    </p>
                    <Row>
                      <Col>
                        <AdvancedUploader
                          onChange={(value: string) => onFormChange('shareFile', value)}
                          value={form.shareUrl}
                          thumb
                        >
                          <AdvancedButton
                            text={translate('PAGES.PANEL.PASSWORDS.LABELS.IMAGE_UPLOAD')}
                            endIcon={<UploadOutlined />}
                          />
                        </AdvancedUploader>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <br />
              <div className="details__title">
                <h3 className="details__title__text">
                  {translate('PAGES.PANEL.PASSWORDS.LABELS.TITLE_DAILY_MEDITATION')}
                </h3>
              </div>
              <Row>
                <Col md={6}>
                  <div className="details__title">
                    <p className="details__title__description">
                      {translate(
                        'PAGES.PANEL.PASSWORDS.LABELS.DESCRIPTION_DAILY_MEDITATION'
                      )}
                    </p>
                    <Row>
                      <Col md={12}>
                        <AdvancedTextInput
                          id="description-daily-meditation-form"
                          value={form.descriptionDailyMeditation || ''}
                          onChange={(val: string) => onFormChange('descriptionDailyMeditation', val)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="details__title">
                    <p className="details__title__description">
                      {translate(
                        'Inclua aqui o áudio da meditação diária'
                      )}
                    </p>
                    <Row>
                      <Col>
                        <AdvancedUploader
                          onChange={(value: string) => onFormChange('file2', value)}
                          value={form.secondFileUrl}
                          hidePreview
                          mp3
                        >
                          <AdvancedButton
                            text={translate('PAGES.PANEL.PASSWORDS.LABELS.UPLOAD_DAILY_MEDITATION')}
                            endIcon={<UploadOutlined />}
                          />
                        </AdvancedUploader>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className="details__form__actions">
                <Row>
                  <Col md={6}>
                    <Link
                      to={getRouteStackPath('ACTIVITIES', 'PASSWORDS_REPORT')}
                    >
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md={6} className="text-right">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordsDetails;
