import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import PrayerDetails from './Details/PrayerDetails';
import PrayerReport from './Report/PrayerReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const PrayerNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('WORSHIP', 'PRAYER_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.PRAYER.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.PRAYER.REPORT.DESCRIPTION')}
        >
          <PrayerReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('WORSHIP', 'PRAYER_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.PRAYER.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.PRAYER.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <PrayerDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default PrayerNavigationStack;
