import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';
import OffersReceivedNavigationStack from './OffersReceived/OffersReceivedNavigationStack';
import OffersReportsNavigationStack from './OffersReports/OffersReportsNavigationStack';

const OffersNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('OFFERS')}>
        <OffersReportsNavigationStack />
        <OffersReceivedNavigationStack />
      </Route>
    </Switch>
  );
};

export default OffersNavigationStack;
