import { Popover } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import IconView from '~/assets/svg/data-table-actions/ic_view.svg';
import MoreDots from '~/assets/svg/ic_more_dots.svg';
import { translate } from '~/services/i18n';

interface IProps {
  row?: any;
  basePath?: string;
  maxWidth?: number;
  type?: string;
}

const DataTableActionView: React.FC<IProps> = (props) => {
  const {
    row,
    basePath,
  } = props;

  const onEdit = (id: string) => {
    return `${basePath}/${id}`;
  };

  return (
    <div
      className="data-table-actions"
      style={{
        width: props.maxWidth || '100%',
        display: props.maxWidth ? 'inline-block' : 'block',
      }}
    >
      <Popover
        placement="leftTop"
        trigger="click"
        content={(
          <div className="data-table-actions__items">
            <div className="data-table-actions__items__single">
              {basePath && (
                <Link
                  className="data-table-actions__items__single__link"
                  to={onEdit(row.id)}
                >
                  <span className="data-table-actions__items__single__link__icon">
                    <img
                      src={IconView}
                      alt="icon"
                    />
                  </span>
                  <span className="data-table-actions__items__single__link__text">
                    {translate('COMPONENTS.DATA_TABLE_ACTIONS.VIEW.LABEL')}
                  </span>
                </Link>
              )}
            </div>
          </div>
        )}
      >
        <div className="data-table-actions__button">
          <img
            src={MoreDots}
            alt="more"
          />
        </div>
      </Popover>
    </div>
  );
};

export default DataTableActionView;
