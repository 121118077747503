import React, { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import * as WorshipActions from '~/actions/worship';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';

import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedFilter from '~/components/AdvancedFilter/AdvancedFilter';

import { AdvancedFilterType } from '@portal/enum/advancedFilter';

import { translate } from '~/services/i18n';
import { useReduxState } from '~/hooks/useReduxState';
import { REPORT_PAGE_SIZE } from '~/config/env';

import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.IWorshipAdvancedFilter = {
  title: null,
  description: null,
  fromDate: null,
  untilDate: null,
  header: null,
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
};

const CultReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);

  const { worship } = useReduxState();

  const searchFields: utils.SearchParams[] = [
    {
      name: 'title',
      placeholder: 'Título',
      type: AdvancedFilterType.TEXT,
      defaultValue: '',
    },
    {
      name: 'fromDate',
      placeholder: 'Data ínicial',
      type: AdvancedFilterType.DATE_PICKER,
      defaultValue: '',
      format: translate('SHARED.FORMAT_DATE'),
    },
    {
      name: 'isDESC',
      placeholder: 'Recentes ou antigas',
      type: AdvancedFilterType.SELECT,
      defaultValue: isDescArray[0].value,
      options: isDescArray
    },
  ];

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (filters: advancedFilterModels.IWorshipAdvancedFilter) => {
    dispatch(WorshipActions.getWorshipReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(WorshipActions.removeWorship(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  };

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  return (
    <div className="report">
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate(
                'PAGES.PANEL.CULT.REPORT.PAGE_TITLE'
              )}
              pageDescription={translate(
                'PAGES.PANEL.CULT.REPORT.PAGE_DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6} className="text-right">
            <Link
              to={`${getRouteStackPath(
                'WORSHIP',
                'CULT_DETAILS'
              )}/${translate('SHARED.ADD_ID')}`}
            >
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <AdvancedFilter
              fields={searchFields}
              onSearch={(filtersData: any) => {
                onSearch({ ...filtersData })
                setAdvancedFilters(filtersData);
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={worship?.list?.rows || []}
                rowCount={worship?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.ID.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.ID.TITLE'
                    ),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.TITLE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.TITLE.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.FROM_DATE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.FROM_DATE.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {o.value && DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATE_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.UNTIL_DATE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.UNTIL_DATE.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {o.value && DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATE_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CULT.REPORT.TABLE.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath(
                          'WORSHIP',
                          'CULT_DETAILS'
                        )}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CultReport;
