import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';
import * as ScheduleActions from '~/actions/schedule';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { EventEnumArray, getEventTypeTitle, getScope, getScopeTypeTitle, Scope } from '~/enum/typeEvent';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { renderOnlyTextFromHtml } from '../../../../utils/string';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.IScheduleAdvancedFilter = {
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  type: [1, 4],
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'title',
    placeholder: 'Título',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'scope',
    placeholder: 'Perfil',
    type: AdvancedFilterType.SELECT,
    defaultValue: '',
    options: [({ name: 'Geral', value: null }), ...getScope.map(o => ({ ...o, value: o.value }))]
  },
  {
    name: 'type',
    placeholder: 'Tipo de evento',
    type: AdvancedFilterType.SELECT,
    defaultValue: '',
    options: EventEnumArray.map(o => ({ ...o, value: o.value }))
  },
  {
    name: 'startsAtDate',
    placeholder: 'Data do evento',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
    maxRange: 30,
    format: translate('SHARED.FORMAT_DATE'),
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const ScheduleReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { schedule, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    const synodId = user.me?.synod?.id || user.me?.user?.synodId;
    if (user.me) {
      onSearch({
        ...advancedFilters,
        ...filter,
        synodId,
        ...synodId && { scope: Scope.SYNODAL }
      });
    }
  }, [user]);

  const onSearch = (filters: advancedFilterModels.IScheduleAdvancedFilter) => {
    dispatch(ScheduleActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(ScheduleActions.remove(id));

    const filter = NavigationService.getQuery();
    const synodId = user.me?.synod?.id || user.me?.user?.synodId;
    onSearch({
      ...advancedFilters,
      ...filter,
      synodId,
      ...synodId && { scope: Scope.SYNODAL }
    });
  };

  return (
    <div className="report">
      <Row>
        <Col>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.TITLE')}
            pageDescription={translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.DESCRIPTION')}
          />
        </Col>
        <Col lg={6} className="text-right">
          <Link
            to={`${getRouteStackPath('ACTIVITIES', 'SCHEDULE_DETAILS')}/${translate(
              'SHARED.ADD_ID'
            )}`}
          >
            <AdvancedButton
              text={translate('SHARED.ADD_NEW')}
              endIcon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              const synodId = user.me?.synod?.id || user.me?.user?.synodId;
              onSearch({
                ...filtersData,
                synodId: user.me?.synod?.id,
                ...synodId && { scope: Scope.SYNODAL }
              });
              setAdvancedFilters(filtersData)
            }
            }
            defaultFields={initialValues}
          />
        </Col>
      </Row>
      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={schedule?.list?.rows || []}
                rowCount={schedule?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.ID.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.ID.TITLE'
                    ),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TITLE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TITLE.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.DESCRIPTION.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.DESCRIPTION.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <div className="reset-html-styles">
                          {renderOnlyTextFromHtml(o.row.description)}
                        </div>
                      )
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.SCOPE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.SCOPE.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{o.row.synod && o.row.scope === Scope.SYNODAL ? o.row.synod.name : getScopeTypeTitle(o.value as number)}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TYPE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TYPE.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{getEventTypeTitle(o.value as number)}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.START_EVENT.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.START_EVENT.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATE_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath(
                          'ACTIVITIES',
                          'SCHEDULE_DETAILS'
                        )}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const synodId = user.me?.synod?.id || user.me?.user?.synodId;
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId,
                    ...synodId && { scope: Scope.SYNODAL }
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ScheduleReport;
