import WorshipRequests from '@portal/repositories/worship';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { WORSHIP_REPORT, WORSHIP_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanWorshipDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: WORSHIP_DETAIL,
  });
};

export const getWorshipReport = (searchParams: advancedFilterModels.IWorshipAdvancedFilter) =>
  async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      const payload: models.BaseReport = await WorshipRequests.getReport(
        {
          ...searchParams,
          offset: searchParams.page - 1,
          limit: searchParams.pageSize,
        }
      );

      if (payload) {
        dispatch({ payload, type: WORSHIP_REPORT });
      }
    } catch (err: any) {
      MessageService.error(err.message);
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getWorshipDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Worship = await WorshipRequests.getDetail(id);

    if (payload) {
      dispatch({ payload, type: WORSHIP_DETAIL });
    }
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const addWorship = (params: models.Worship) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await WorshipRequests.add(params);

    MessageService.success('PAGES.PANEL.WORSHIP.FORM.SUCCESS_ADD_MESSAGE');

    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const editWorship = (id: string, params: models.Worship) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await WorshipRequests.update(id, params);

    MessageService.success('PAGES.PANEL.WORSHIP.FORM.SUCCESS_EDIT_MESSAGE');

    await centralNavigationService.back();

  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeWorship = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await WorshipRequests.remove(id);

    MessageService.success('PAGES.PANEL.WORSHIP.FORM.SUCCESS_REMOVE_MESSAGE');
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
