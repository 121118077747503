import {
  OCCUPATION_AREA_ALL,
  OCCUPATION_AREA_DETAILS,
  OCCUPATION_AREA_DETAILS_CATEGORY,
  OCCUPATION_AREA_REPORT,
  OCCUPATION_AREA_REPORT_CATEGORY,
} from '~/actions/actionTypes';

const initialState: reducers.OccupationAreaReducer = {
  detail: null,
  detailCategory: null,
  list: null,
  all: [],
  listCategories: null,
};

const occupationAreaReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {

    case OCCUPATION_AREA_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    case OCCUPATION_AREA_REPORT_CATEGORY:
      state = {
        ...state,
        listCategories: action.payload,
      };
      break;

    case OCCUPATION_AREA_DETAILS:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case OCCUPATION_AREA_DETAILS_CATEGORY:
      state = {
        ...state,
        detailCategory: action.payload,
      };
      break;

    case OCCUPATION_AREA_ALL:
      state = {
        ...state,
        all: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default occupationAreaReducer;
