import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';
import CultNavigationStack from './Cult/CultNavigationStack';
import LiturgyNavigationStack from './Liturgy/LiturgyNavigationStack';
import PrayerNavigationStack from './Prayer/PrayerNavigationStack';

const WorshipNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('WORSHIP')}>
        <CultNavigationStack />
        <LiturgyNavigationStack />
        <PrayerNavigationStack />
      </Route>
    </Switch>
  );
};

export default WorshipNavigationStack;
