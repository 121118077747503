import React, { useEffect, useState } from 'react';
import { useReduxState } from '@portal/hooks/useReduxState';
import { Popover } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as SynodActions from '~/actions/synod';

import UserIcon from '~/assets/svg/ic_user.svg';
import { translate } from '~/services/i18n';

import { AuthActions } from '../../actions';
import AdvancedSelect from '../AdvancedSelect/AdvancedSelect';
import { setSynodalView } from '@portal/actions/user';
import { ProfileType } from '@portal/enum/profileType';

interface IProps {
  user?: models.User | null;
}

const PanelContentTopBar: React.FC<IProps> = (props: IProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { synod, user } = useReduxState();

  const [synodId, setSynodId] = useState(1);

  const onLogout = () => {
    dispatch(AuthActions.logout());
  };

  const handleSynodChange = (id: any) => {
    if (id !== 1) {
      dispatch(setSynodalView(id, user.me?.user as models.User, ProfileType.SYNOD_ADMIN));
    } else {
      dispatch(setSynodalView(null, user.me?.user as models.User, ProfileType.ADMIN));
    }
    setSynodId(id);
  };

  useEffect(() => {
    dispatch(SynodActions.getAllSynods());
  }, []);

  return (
    <div className="panel-content-top-bar">
      <div className="panel-content-top-bar__menu">
        <div
          className={`panel-content-top-bar__menu__item ${pathname === '/dashboard/detalhes'
            ? 'panel-content-top-bar__menu__item--active'
            : ''
            }`}
        >
          <Link
            className="panel-content-top-bar__menu__item__link"
            to={'/dashboard/detalhes'}
          >
            <span className="panel-content-top-bar__menu__item__link__title">
              {translate(
                'COMPONENTS.PANEL_CONTENT_TOP_BAR.PAGES.DASHBOARD.TITLE'
              )}
            </span>
          </Link>
        </div>
      </div>
      <div className="panel-content-top-bar__right">
        <div className="panel-content-top-bar__right">
          {
            !user.me?.synod?.name ?
              <AdvancedSelect
                value={synodId}
                variant="standard"
                onChange={handleSynodChange}
                options={[{ id: 1, name: user.me?.user.name }, ...synod.allSynod]}
              />
              :
              <span className="panel-content-top-bar__right__user-dropdown__name">
                {user.me?.synod ? user.me?.synod.name : user.me?.user.name}
              </span>
          }
        </div>
        <div className="panel-content-top-bar__right__user-dropdown">
          <Popover
            placement="bottomRight"
            content={
              <div
                onClick={onLogout}
                className="panel-content-top-bar__right__user-dropdown__logout"
              >
                {translate(
                  'COMPONENTS.PANEL_CONTENT_TOP_BAR.PAGES.DASHBOARD.LOGOUT'
                )}
              </div>
            }
          >
            <span className="panel-content-top-bar__right__user-dropdown__wrapper">
              <span className="panel-content-top-bar__right__user-dropdown__thumb">
                <img
                  className="panel-content-top-bar__right__user-dropdown__thumb__image"
                  src={UserIcon}
                  alt={'user'}
                />
              </span>
              <span className="panel-content-top-bar__right__user-dropdown__name">
                {props.user?.name}
              </span>
            </span>
          </Popover>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(AuthActions.logout()),
});

export default connect(null, mapDispatchToProps)(PanelContentTopBar);
