import EventApi from '~/repositories/event';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';
import { GET_ALL_EVENTS } from './actionTypes';

import { decreaseLoading, increaseLoading } from './loading';

export const createEvent = (eventPayload: models.RequestSchedule) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload = await EventApi.createEvent(eventPayload);
    if (payload) {
      centralNavigationService.back();
      MessageService.success('Evento cadastrador com sucesso!');
    }
  } catch (error: any) {
    MessageService.error(error.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getAllEvent = (params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    const payload = await EventApi.getAll(params);

    dispatch({
      type: GET_ALL_EVENTS,
      payload,
    });
  } finally {
    dispatch(decreaseLoading());
  }
};
