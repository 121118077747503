import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';

import NewsNavigationStack from './News/NewsNavigationStack';
import NotificationsNavigationStack from './Notifications/NotificationsNavigationStack';
import PasswordsNavigationStack from './Passwords/PasswordsNavigationStack';
import AboutPasswordsNavigationStack from './AboutPasswords/AboutPasswordsNavigationStack';
import ScheduleNavigationStack from './Schedule/ScheduleNavigationStack';
import CallToActionNavigationStack from './CallToAction/CallToActionNavigationStack';
import PdfNavigationStack from './Pdf/PdfNavigationStack';
import PdfOfferNavigationStack from './PdfOffer/PdfOfferNavigationStack';
import PdfSinodNavigationStack from './PdfSinod/PdfSinodNavigationStack';
import CampaignsNavigationStack from './Campaigns/CampaignsNavigationStack';
import MessagesNavigationStack from './Messages/MessagesNavigationStack';

const ActivitiesNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('ACTIVITIES')}>
        <ScheduleNavigationStack />
        <PdfNavigationStack />
        <PdfOfferNavigationStack />
        <PdfSinodNavigationStack />
        <NewsNavigationStack />
        <PasswordsNavigationStack />
        <AboutPasswordsNavigationStack />
        <NotificationsNavigationStack />
        <CallToActionNavigationStack />
        <CampaignsNavigationStack />
        <MessagesNavigationStack />
      </Route>
    </Switch>
  );
};

export default ActivitiesNavigationStack;
