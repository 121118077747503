import { FileType } from '~/enum/newsType';

export const imageExtensions = ['png', 'jpg', 'jpeg'];

export const audioExtensions = ['mpeg'];

export const applicationExtensions = ['pdf'];

export function getFileType(extension: string) {
  let type = FileType.OTHER;

  if (imageExtensions.includes(extension)) {
    type = FileType.IMAGE;
  }

  if (audioExtensions.includes(extension)) {
    type = FileType.AUDIO;
  }

  if (applicationExtensions.includes(extension)) {
    type = FileType.OTHER;
  }

  return type;
}

export const openPdf = (url: string | undefined) => {
  if(!url) { return; }
  const res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  if (res) {
    window.open(
      url,
      '_blank'
    );
  } else {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = 'pdfdownloaded';
    downloadLink.click();
  }
};