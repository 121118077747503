import { LeftOutlined, PlusCircleOutlined, CloudUploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedUploader from '~/components/AdvancedUploader/AdvancedUploader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import * as CommunityActions from '~/actions/community';
import * as ParishActions from '~/actions/parish';
import { FileCommunity } from '~/models/module';

import { getRouteStackPath } from '~/config/routes';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

import { useReduxState } from '~/hooks/useReduxState';
import { ProfileType } from '~/enum/profileType';
import AdvancedSelect from '~/components/AdvancedSelect/AdvancedSelect';
import { getAddressByCep, cleanCepDetails } from '~/actions/utils';
import { cepMask } from '@portal/utils/string';
import { FileType } from '@portal/enum/newsType';

const formInitialValues: models.Community = {
  id: '',
  name: '',

  parishId: null,

  address: {
    zipcode: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    complement: '',

    latitude: '',
    longitude: '',
  },

  title: '',
  description: '',
  mediaUrl: '',
  files: [],
};

const CommunityDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [file, setFile] = useState<FileCommunity | null>();
  const [pageType] = useState(getPagetType());

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { community, auth, parish, utils, user } = useReduxState();

  const onFormChange = async (key: string, val: any, address?: boolean) => {
    if (address) {
      setForm((prevState: models.Community) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [key]: val,
        },
      }));
    } else {
      setForm((prevState: models.Community) => ({ ...prevState, [key]: val }));
    }

    if (key === 'zipcode' && val && val.length === 9) {
      dispatch(getAddressByCep(val));
    }
  };

  useEffect(() => {
    if (community && community.detail) {
      setForm(community.detail);
    } else {
      setForm(formInitialValues);
    }
  }, [community.detail]);

  useEffect(() => {
    if (utils.list) {
      setForm({
        ...form,
        address: {
          ...form.address,
          street: utils.list.logradouro || '',
          neighborhood: utils.list.bairro || '',
          city: utils.list.localidade || '',
          state: utils.list.uf || '',
        },
      });
    }
  }, [utils.list]);

  useEffect(() => {
    if (user.me) {
      dispatch(ParishActions.getAll(user.me?.synod?.id));
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CommunityActions.getDetail(params.id));
    } else {
      dispatch(cleanCepDetails());
      dispatch(CommunityActions.cleanDetails());
    }
  }, [pathname, pageType, user]);

  const onFormSubmit = () => {

    const fileList = [];

    if (!form.name) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NAME');
    }

    if (auth?.me?.profileType === ProfileType.ADMIN && !form.parishId) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_SYNOD_ID');
    }

    if (!form.address?.zipcode) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_ZIPCODE');
    }

    if (!form.address?.street) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_STREET');
    }

    if (!form.address?.number) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NUMBER');
    }

    if (!form.address?.city) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_CITY');
    }

    if (!form.address?.state) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_STATE');
    }

    if (file) {
      fileList.push(
        {
          id: form.files ? form.files[0]?.file?.id : null,
          name: file?.name || null,
          type: FileType.IMAGE,
          base64: file?.base64 || null,
          extension: file?.extension || null,
          isPrivate: false,
        }
      );
    }

    const payload: models.Community = {
      ...form,
      address: {
        ...form.address,
        ...form.address.latitude === '' && {
          latitude: null
        },
        ...form.address.longitude === '' && {
          longitude: null
        },
      },
      files: fileList
    };

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CommunityActions.update(community?.detail?.id, payload));
    } else {
      delete form.id;
      dispatch(CommunityActions.create(payload));
    }
  };

  const setReportLinkYoutube = (linkYT: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = linkYT.match(regExp);
    let videoId = null;
    if (match && match[7].length === 11) {
      videoId = match[7];
    }
    if (linkYT.length > 24) {
      if (videoId === null) {
        return MessageService.error(
          'PAGES.PANEL.USER.COMMUNITY.REPORT.FORM.ERRORS.MEDIA_URL'
        );
      }
    }
    onFormChange('mediaUrl', linkYT);
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.USER.COMMUNITY.REPORT.TITLE'),
                url: getRouteStackPath('USER', 'COMMUNITY_REPORT'),
                active: true,
              },
              {
                title: translate(
                  `PAGES.PANEL.USER.COMMUNITY.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                ),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.USER.COMMUNITY.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.USER.COMMUNITY.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.NAME.LABEL'
                    )}
                    value={form.name}
                    onChange={(val: string) => onFormChange('name', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedSelect
                    label={
                      !(pageType === PAGE_TYPE.EDIT) &&
                      translate(
                        'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.PARISH.LABEL'
                      )
                    }
                    value={form.parishId}
                    onChange={(val: string) => onFormChange('parishId', val)}
                    options={parish.all}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="details__form__subtitle">
                    <h3>Endereço</h3>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.ZIPCODE.LABEL'
                    )}
                    value={cepMask(form.address?.zipcode)}
                    onChange={(val: string) =>
                      onFormChange('zipcode', val, true)
                    }
                    type="tel"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.STREET.LABEL'
                    )}
                    value={form.address?.street}
                    onChange={(val: string) =>
                      onFormChange('street', val, true)
                    }
                  />
                </Col>
                <Col md={2}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.NUMBER.LABEL'
                    )}
                    value={form.address?.number}
                    onChange={(val: string) =>
                      onFormChange('number', val, true)
                    }
                    type="tel"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.NEIGHBORHOOD.LABEL'
                    )}
                    value={form.address?.neighborhood}
                    onChange={(val: string) =>
                      onFormChange('neighborhood', val, true)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.CITY.LABEL'
                    )}
                    value={form.address?.city}
                    onChange={(val: string) => onFormChange('city', val, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.STATE.LABEL'
                    )}
                    value={form.address?.state}
                    onChange={(val: string) => onFormChange('state', val, true)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.COMPLEMENT.LABEL'
                    )}
                    value={form.address?.complement}
                    onChange={(val: string) =>
                      onFormChange('complement', val, true)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.LATITUDE.LABEL'
                    )}
                    value={form.address?.latitude}
                    onChange={(val: string) =>
                      onFormChange('latitude', val, true)
                    }
                  />
                </Col>
                <Col md={3}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.LONGITUDE.LABEL'
                    )}
                    value={form.address?.longitude}
                    onChange={(val: string) =>
                      onFormChange('longitude', val, true)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="details__form__subtitle">
                    <h3>{translate('PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.SUBTITLES.WHERE_ARE_WE.LABEL')}</h3>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.WHERE_ARE_WE.TITLE.LABEL'
                    )}
                    value={form.title}
                    onChange={(val: string) =>
                      onFormChange('title', val)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.WHERE_ARE_WE.MEDIA_URL.LABEL'
                    )}
                    value={form.mediaUrl}
                    onChange={(val: string) => {
                      onFormChange('mediaUrl', val);
                      setReportLinkYoutube(val);
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.WHERE_ARE_WE.DESCRIPTION.LABEL'
                    )}
                    multiline
                    value={form.description}
                    onChange={(val: string) =>
                      onFormChange('description', val)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <label>
                    {translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.WHERE_ARE_WE.UPLOAD.LABEL'
                    )}
                  </label>
                  <AdvancedUploader
                    onChange={setFile}
                    defaultImage={
                      file?.base64 ||
                      (form &&
                        form.files &&
                        form.files[0]?.file &&
                        form.files[0]?.file?.url
                      )
                    }
                    value={
                      file?.base64 ||
                      (form &&
                        form.files &&
                        form.files[0]?.file &&
                        form.files[0]?.file?.url)
                    }
                  >
                    <div>
                      <AdvancedButton
                        className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                        text={translate(
                          'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.WHERE_ARE_WE.BUTTON_UPLOAD'
                        )}
                        endIcon={<CloudUploadOutlined />}
                      />
                    </div>
                  </AdvancedUploader>
                </Col>
              </Row>

              <div className="details__form__actions">
                <Row className="details__form__actions__row">
                  <Col md="auto">
                    <Link to={getRouteStackPath('USER', 'COMMUNITY_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md="auto">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default CommunityDetails;
