import {
  CAMPAIGNS_DETAILS,
  CAMPAIGNS_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.CampaignsReducer = {
  detail: null,
  list: null,
};

const campaignReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {

    case CAMPAIGNS_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    case CAMPAIGNS_DETAILS:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default campaignReducer;
