import StateRequests from '~/repositories/state';
import { STATE_DETAIL, STATE_REPORT } from './actionTypes';
import * as MessageService from '~/services/message';
import { decreaseLoading, increaseLoading } from './loading';
import centralNavigationService from '~/services/navigation';
import { State } from '@portal/models/module';

export const cleanDetails = () => async (dispatch: any) => {
  await dispatch({
    payload: null,
    type: STATE_DETAIL,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.IBaseFilter | null
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.State> = await StateRequests.getReport(
      searchParams
    );

    dispatch({
      payload,
      type: STATE_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.State = await StateRequests.getDetail(id);

    dispatch({
      payload,
      type: STATE_DETAIL,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: State) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await StateRequests.create(params);

    MessageService.success('PAGES.PANEL.NEWS.MESSAGES.SUCCESS_ADD_MESSAGE');
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await StateRequests.remove(id);
    MessageService.success('PAGES.PANEL.NEWS.MESSAGES.SUCCESS_DELETE_MESSAGE');
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await StateRequests.update(id, params);

    MessageService.success('PAGES.PANEL.NEWS.MESSAGES.SUCCESS_EDIT_MESSAGE');
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
