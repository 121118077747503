import WorshipContentRequests from '@portal/repositories/worshipContent';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { WORSHIP_CONTENT_REPORT, WORSHIP_CONTENT_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanWorshipContentDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: WORSHIP_CONTENT_DETAIL,
  });
};

export const getWorshipContentReport = (searchParams: advancedFilterModels.IWorshipContentAdvancedFilter) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.BaseReport = await WorshipContentRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );
    if (payload) {
      dispatch({ payload, type: WORSHIP_CONTENT_REPORT });
    }
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getWorshipContentDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.WorshipContent = await WorshipContentRequests.getDetail(id);

    if (payload) {
      dispatch({ payload, type: WORSHIP_CONTENT_DETAIL });
    }
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const addWorshipContent = (params: models.WorshipContent, worshipContentType: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await WorshipContentRequests.add(params);

    MessageService.success(`PAGES.PANEL.${worshipContentType}.FORM.SUCCESS_ADD_MESSAGE`);

    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const editWorshipContent = (id: string, params: models.WorshipContent, worshipContentType: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await WorshipContentRequests.update(id, params);

    MessageService.success(`PAGES.PANEL.${worshipContentType}.FORM.SUCCESS_EDIT_MESSAGE`);

    await centralNavigationService.back();

  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeWorshipContent = (id: string, worshipContentType: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await WorshipContentRequests.remove(id);

    MessageService.success(`PAGES.PANEL.${worshipContentType}.FORM.SUCCESS_REMOVE_MESSAGE`);
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
