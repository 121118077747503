import { translate } from '~/services/i18n';

export enum ProfileType {
  ADMIN = 1,
  SYNOD_ADMIN = 2,
  APP_USER = 3,
  MINISTER_USER = 4
}

export const profileTypeArray = [
  {
    value: ProfileType.ADMIN,
    i18n: 'ENUMERATORS.PROFILE_TYPE.ADMIN.LABEL',
    name: 'Nacional',
  },
  {
    value: ProfileType.SYNOD_ADMIN,
    i18n: 'ENUMERATORS.PROFILE_TYPE.SYNOD_ADMIN.LABEL',
    name: 'Sinodal',
  },
  {
    value: ProfileType.APP_USER,
    i18n: 'ENUMERATORS.PROFILE_TYPE.APP_USER.LABEL',
    name: 'Usuário(a)',
  },
  {
    value: ProfileType.MINISTER_USER,
    i18n: 'ENUMERATORS.PROFILE_TYPE.MINISTER_USER.LABEL',
    name: 'Ministro(a)',
  },
];

export const getProfileTypeTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = profileTypeArray.find(o => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};
