import { translate } from '~/services/i18n';

export enum LeaderRole {
  MINISTER = 1,
  COUNCIL = 2,
  BOARD = 3,
  PRESIDENT = 4,
  MINISTER_RETIRED = 5,
}

export const leaderRoleArray = [
  {
    value: LeaderRole.MINISTER,
    i18n: 'ENUMERATORS.LEADER_ROLE.MINISTER.LABEL',
    name: 'Ministras e ministros',
  },
  {
    value: LeaderRole.PRESIDENT,
    i18n: 'ENUMERATORS.LEADER_ROLE.PRESIDENT.LABEL',
    name: 'Presidente',
  },
  {
    value: LeaderRole.MINISTER_RETIRED,
    i18n: 'ENUMERATORS.LEADER_ROLE.EMERITUS.LABEL',
    name: 'Emerito',
  },
];

export const getLeaderRoleTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = leaderRoleArray.find((o) => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};
