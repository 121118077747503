import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';

import * as CallToActionActions from '~/actions/callToAction';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { isDescArray } from '@portal/enum/isDesc';


const initialValues: advancedFilterModels.IBaseFilter = {
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'name',
    placeholder: 'Nome',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'createdAt',
    placeholder: 'Data de criação',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
    format: translate('SHARED.FORMAT_DATE'),
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const CallToActionReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { callToAction } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.IBaseFilter) => {
    dispatch(CallToActionActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(CallToActionActions.remove(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  };

  return (
    <div className="report">
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate('PAGES.PANEL.CALL_TO_ACTION.REPORT.TITLE')}
              pageDescription={translate(
                'PAGES.PANEL.CALL_TO_ACTION.REPORT.DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6} className="text-right">
            <Link
              to={`${getRouteStackPath('ACTIVITIES', 'CALLTOACTION_DETAILS')}/${translate(
                'SHARED.ADD_ID'
              )}`}
            >
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <AdvancedFilter
              fields={searchFields}
              onSearch={(filtersData: any) => {
                onSearch({
                  ...filtersData,
                });
                setAdvancedFilters(filtersData);
              }}
              defaultFields={initialValues}
            />
          </Col>
        </Row>
      </div>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={callToAction?.list?.rows || []}
                rowCount={callToAction?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.ID.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.ID.TITLE'
                    ),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.NAME.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.NAME.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.DESCRIPTION.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.DESCRIPTION.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.CREATED_AT.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.CREATED_AT.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.CALL_TO_ACTION.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath('ACTIVITIES', 'CALLTOACTION_DETAILS')}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };

                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CallToActionReport;
