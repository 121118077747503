import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';

import * as LeadersActions from '~/actions/leaders';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { getLeaderRoleTitle, leaderRoleArray } from '~/enum/leaderRoleType';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.ILeadersAdvancedFilter = {
  name: '',
  page: 1,
  orderBy: 'createdAt',
  isDESC:  isDescArray[0].value,
  pageSize: REPORT_PAGE_SIZE,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'name',
    placeholder: 'Nome',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'roleType',
    placeholder: 'Perfil',
    type: AdvancedFilterType.SELECT,
    defaultValue: '',
    options: leaderRoleArray.map(o => ({ ...o, value: o.value, name: o.name }))
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const LeadersReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { leader, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    if (user.me) {
      onSearch({
        ...advancedFilters,
        ...filter,
        synodId: user.me?.synod?.id,
      });
    }
  }, [user.me]);

  const onSearch = (filters: advancedFilterModels.ILeadersAdvancedFilter) => {
    dispatch(LeadersActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(LeadersActions.remove(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
      synodId: user.me?.synod?.id,
    });
  };

  return (
    <div className="report">
      <Row>
        <Col>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.USER.LEADERS.REPORT.TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.USER.LEADERS.REPORT.DESCRIPTION'
            )}
          />
        </Col>
        <Col lg={6} className="text-right">
          <Link
            to={`${getRouteStackPath('USER', 'LEADER_DETAILS')}/${translate(
              'SHARED.ADD_ID'
            )}`}
          >
            <AdvancedButton
              text={translate('SHARED.ADD_NEW')}
              endIcon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              onSearch({ ...filtersData, synodId: user.me?.synod?.id, })
              setAdvancedFilters(filtersData)
            }}
          />
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={leader?.list?.rows || []}
                rowCount={leader?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ID.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ID.TITLE'
                    ),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.NAME.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.NAME.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.TYPE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.TYPE.TITLE'
                    ),
                    sortable: false,
                    hide: false,
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {typeof o.value === 'number' &&
                            getLeaderRoleTitle(o.value)}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.CREATED_AT.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.CREATED_AT.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath('USER', 'LEADER_DETAILS')}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId: user.me?.synod?.id,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LeadersReport;
