import { CellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useReduxState } from '~/hooks/useReduxState';

import * as TransactionActions from '~/actions/transaction';
import DataTable from '~/components/DataTable/DataTable';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { getPaymentType } from '@portal/enum/transactionType';
import { DateTime } from 'luxon';
import DataTableActionView from '@portal/components/DataTableActionView/DataTableActionView';
import { EventType, Scope } from '@portal/enum/typeEvent';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.IOfferAdvancedFilter = {
  name: '',
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  type: EventType.OFFERING,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'value',
    placeholder: 'Valor',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'paymentCapturedAt',
    placeholder: 'Data da oferta',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
    maxRange: 30,
    format: translate('SHARED.FORMAT_DATE'),
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const ScheduleReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { transaction, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    if (user.me) {
      onSearch({
        ...advancedFilters,
        ...filter,
        synodId: user.me?.synod?.id || user.me?.user?.synodId,
      });
    }
  }, [user]);

  const onSearch = (filters: advancedFilterModels.IScheduleAdvancedFilter) => {
    dispatch(TransactionActions.getReport(filters));
  };

  return (
    <div className="report">
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate(
                'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TITLE'
              )}
              pageDescription={translate(
                'PAGES.PANEL.OFFERS_RECEIVED.REPORT.DESCRIPTION'
              )}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              const synodId = user.me?.synod?.id || user.me?.user?.synodId;
              onSearch({
                ...filtersData,
                synodId,
                type: EventType.OFFERING,
                ...synodId && { scope: Scope.SYNODAL }
              });
              setAdvancedFilters(filtersData);
            }
            }
            defaultFields={initialValues}
          />
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={transaction?.list?.rows || []}
                rowCount={transaction?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.FIELD_ID'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.ID'
                    ),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.FIELD_EVENT'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.EVENT'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams | any) => {
                      return <>{o.value.title}</>;
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.FIELD_VALUE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.VALUE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{`${translate('SHARED.CURRENCY_SYMBOL')} ${o.value}`}</>;
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.FIELD_CREATED_AT'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.CREATED_AT'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams | any) => {
                      return <>
                        {DateTime.fromISO(o.value as string).toLocaleString(
                          DateTime.DATE_SHORT
                        )}
                      </>;
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.FIELD_PAYMENT_TYPE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OFFERS_RECEIVED.REPORT.TABLE.PAYMENT_TYPE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => (
                      <>{getPaymentType(o.value as number)}</>
                    )
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADVANCED_FILTER.ACTIONS'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.PASSWORDS.LABELS.ACTIONS'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActionView
                        row={o.row}
                        basePath={getRouteStackPath('OFFERS', 'OFFERS_RECEIVED_DETAILS')}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId: user.me?.synod?.id|| user.me?.user?.synodId,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ScheduleReport;
