import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import LiturgyDetails from './Details/LiturgyDetails';
import LiturgyReport from './Report/LiturgyReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const LiturgyNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('WORSHIP', 'LITURGY_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.LITURGY.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.LITURGY.REPORT.DESCRIPTION')}
        >
          <LiturgyReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('WORSHIP', 'LITURGY_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.LITURGY.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.LITURGY.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <LiturgyDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default LiturgyNavigationStack;
