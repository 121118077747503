import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import * as SynodActions from '~/actions/synod';
import * as ParishActions from '~/actions/parish';
import * as CommunityActions from '~/actions/community';
import ChurchCardIconOne from '~/assets/svg/details-cards/ic_church_card_one.svg';
import DataTable from '~/components/DataTable/DataTable';
import KPICard from '~/components/KPICard/KPICard';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { useReduxState } from '@portal/hooks/useReduxState';
import { ProfileType } from '@portal/enum/profileType';

const initialValues: advancedFilterModels.ISynodAdvancedFilter = {
  name: '',
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};
const parishValues: advancedFilterModels.IParishAdvancedFilter = {
  name: '',
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};
const communityValues: advancedFilterModels.ICommunityAdvancedFilter = {
  name: '',
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};

const PanelDashboardDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useReduxState();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);

  const onSearchParish = (filters: advancedFilterModels.IParishAdvancedFilter) => {
    dispatch(ParishActions.getReport({ ...filters, synodId: user.me?.synod?.id, }));
  };


  useEffect(() => {
    onSearchParish({ ...parishValues, synodId: user.me?.synod?.id, });
    dispatch(CommunityActions.getReport({ ...communityValues, synodId: user.me?.synod?.id, }));
  }, [user.me]);

  const synodRows = useSelector(
    (state: reducers.rootReducer) => state.synod.list
  );

  const parishRows = useSelector(
    (state: reducers.rootReducer) => state.parish.list
  );

  const communityRows = useSelector(
    (state: reducers.rootReducer) => state.community.list
  );

  useEffect(() => {
    const filter = NavigationService.getQuery();

    onSearch({
      ...advancedFilters,
      ...filter,
      synodId: user.me?.synod?.id,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.ISynodAdvancedFilter) => {
    dispatch(SynodActions.getReport(filters));
  };

  return (
    <div className="report">
      <div className="report__header">
        {translate('PAGES.PANEL.DASHBOARD.DETAILS.WELCOME')}
        {user.me?.user?.name}
      </div>
      <div className="panel-dashboard__title">
        <PanelContentHeader
          pageTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION'
          )}
        />
      </div>

      <div className="report__header">
        <Row>
          {
            user.me?.user?.profileType === ProfileType.ADMIN &&
            <Col>
              < KPICard
                icon={ChurchCardIconOne}
                number={synodRows?.count || 0}
                description={translate(
                  'PAGES.PANEL.DASHBOARD.DETAILS.CARDS.ONE.DESCRIPTION'
                )}
              />
            </Col>
          }
          <Col>
            <KPICard
              icon={ChurchCardIconOne}
              number={parishRows?.count || 0}
              description={translate(
                'PAGES.PANEL.DASHBOARD.DETAILS.CARDS.TWO.DESCRIPTION'
              )}
            />
          </Col>
          <Col>
            <KPICard
              icon={ChurchCardIconOne}
              number={communityRows?.count || 0}
              description={translate(
                'PAGES.PANEL.DASHBOARD.DETAILS.CARDS.THREE.DESCRIPTION'
              )}
            />
          </Col>
        </Row>
      </div>

      <Row>
        {
          user.me?.user?.profileType === ProfileType.ADMIN &&
          <Col>
            <div className="report__header" style={{ marginTop: '15px' }}>
              <PanelContentHeader
                pageTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.TABLE.TITLE_SYNOD')}
              />
            </div>
          </Col>
        }
        <Col>
          <div className="report__header" style={{ marginTop: '15px' }}>
            <PanelContentHeader
              pageTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.TABLE.TITLE_PARISH')}
            />
          </div>
        </Col>
        <Col>
          <div className="report__header" style={{ marginTop: '15px' }}>
            <PanelContentHeader
              pageTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.TABLE.TITLE_COMMUNITY')}
            />
          </div>
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={parishRows?.rows || []}
                rowCount={parishRows?.count || 0}
                columns={[
                  {
                    field: 'id',
                    flex: 1,
                    headerName: 'id',
                    sortable: false,
                    hide: true,
                  },
                  {
                    field: 'name',
                    flex: 1,
                    headerName: 'Paróquia',
                  },
                  {
                    field: 'createdAt',
                    flex: 1,
                    headerName: 'Criação',
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId: user.me?.synod?.id,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearchParish({ ...searchFilters, synodId: user.me?.synod?.id, });

                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PanelDashboardDetails;
