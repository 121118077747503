import TransactionRequest from '~/repositories/transactions';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { TRANSACTION_REPORT, TRANSACTION_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: TRANSACTION_DETAIL,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.IBaseFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Transaction> = await TransactionRequest.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: TRANSACTION_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Transaction = await TransactionRequest.getDetail(id);

    dispatch({
      payload,
      type: TRANSACTION_DETAIL,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.Transaction) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await TransactionRequest.create(params);

    MessageService.success(
      'PAGES.PANEL.USER.ADMIN.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await TransactionRequest.remove(id);
    MessageService.success(
      'PAGES.PANEL.USER.ADMIN.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await TransactionRequest.update(id, params);

    MessageService.success(
      'PAGES.PANEL.USER.ADMIN.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
