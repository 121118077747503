import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';
import { renderOnlyTextFromHtml } from '../../../../utils/string';
import * as OccupationAreaActions from '~/actions/occupationArea';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.IOccupationAreaAdvancedFilter = {
  title: '',
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  type: 5,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'title',
    placeholder: 'Título',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const CategoryReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { occupationArea, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.IOccupationAreaAdvancedFilter) => {
    dispatch(OccupationAreaActions.getReportCategory(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(OccupationAreaActions.removeCategory(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  };

  return (
    <div className="report">
      <Row>
        <Col lg={6}>
          <PanelContentHeader
            pageTitle={translate(
              'PAGES.PANEL.OCCUPATION_AREA.CATEGORY.REPORT.TITLE'
            )}
            pageDescription={translate(
              'PAGES.PANEL.OCCUPATION_AREA.CATEGORY.REPORT.PAGE_DESCRIPTION'
            )}
          />
        </Col>
        <Col lg={6} className="text-right">
          <Link
            to={`${getRouteStackPath(
              'OCCUPATION_AREA',
              'CATEGORY_DETAILS'
            )}/${translate('SHARED.ADD_ID')}`}
          >
            <AdvancedButton
              text={translate('SHARED.ADD_NEW')}
              endIcon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              onSearch({ ...filtersData })
              setAdvancedFilters(filtersData);
            }}
          />
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={occupationArea?.listCategories?.rows || []}
                rowCount={occupationArea?.listCategories?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.OCCUPATION_AREA.CATEGORY.REPORT.TABLE.FIELD_TITLE'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OCCUPATION_AREA.CATEGORY.REPORT.TABLE.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.OCCUPATION_AREA.CATEGORY.REPORT.TABLE.FIELD_DESCRIPTION'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.OCCUPATION_AREA.CATEGORY.REPORT.TABLE.DESCRIPTION'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {renderOnlyTextFromHtml(o.row.description)}
                        </>
                      )
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath(
                          'OCCUPATION_AREA',
                          'CATEGORY_DETAILS'
                        )}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId: user.me?.synod?.id,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CategoryReport;
