import InstitutionalRequests from '@portal/repositories/institutional';
import * as MessageService from '~/services/message';

import { INSTITUTIONAL_REPORT, INSTITUTIONAL_UPDATE } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getInstitutionalReport = () => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.InstitutionalResponse = await InstitutionalRequests.getReport();
    if (payload) {
      dispatch({ payload, type: INSTITUTIONAL_REPORT });
    }
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const editInstitutional = (params: models.InstitutionalUpdateRequest) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.InstitutionalResponse = await InstitutionalRequests.editInstitutional(params);
    if (payload) {
      dispatch({ payload, type: INSTITUTIONAL_UPDATE });
      MessageService.success('PAGES.PANEL.INSTITUTIONAL.FORM.SUCCESS_EDIT_MESSAGE');
    }
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};