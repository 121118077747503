import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import PDFOfferDetails from '../PdfOffer/Details/PdfOfferDetails';
import PDFOfferReport from '../PdfOffer/Report/PdfOfferReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const PDFNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ACTIVITIES', 'PDF_OFFER_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.TITLE_OFFER')}
          pageDescription={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.DESCRIPTION_OFFER')}
        >
        <PDFOfferReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('ACTIVITIES', 'PDF_OFFER_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.PAGE_OFFER_TITLE`)}
          pageDescription={translate(`PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <PDFOfferDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default PDFNavigationStack;
