import React, { useEffect, useState } from 'react';
import {
  LeftOutlined,
  PlusCircleOutlined,
  CloudUploadOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { DateTime } from 'luxon';

import * as PdfActions from '~/actions/pdf';
import * as SynodActions from '~/actions/synod';

import { getRouteStackPath } from '~/config/routes';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { getPagetType } from '@portal/utils/page';
import { useReduxState } from '@portal/hooks/useReduxState';
import { PAGE_TYPE } from '@portal/enum/pageType';
import { maskDate } from '@portal/services/masks';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';
import { ProfileType } from '@portal/enum/profileType';
import { PdfType } from '~/enum/pdfType';

const initialValues: models.AddPdf = {
  file: [],
  date: '',
  label: '',
  synodId: null,
};

const PDFOfferDetails: React.FC = (props) => {
  const [form, setForm] = useState(initialValues);
  const [file, setFile] = useState<any>();
  const [pageType] = useState(getPagetType());

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { pdf, synod, user } = useReduxState();

  const onFormChange = (key: string, value: string) => {
    setForm((prevState: models.AddPdf) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onFormSubmit = () => {

    if (!DateTime.fromFormat(form.date as string, translate('SHARED.FORMAT_DATE')).toJSDate().getTime()) {
      return MessageService.error('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ERRORS.EMPTY_DATE');
    }

    if (!form.label) {
      return MessageService.error('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ERRORS.EMPTY_LABEL');
    }

    if (!file && pageType === PAGE_TYPE.ADD) {
      return MessageService.error('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ERRORS.EMPTY_UPLOAD');
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(PdfActions.update(params.id, {
        ...form,
        date: DateTime.fromFormat(
          form.date as string,
          translate('SHARED.FORMAT_DATE')
        ).toISODate(),
        file: file ?
          {
            id: pdf.detail && pdf.detail.file[0].fileId,
            name: pdf.detail && pdf.detail.file[0].file.name,
            type: 4,
            base64: file?.base64,
            extension: file?.extension,
            isPrivate: false,
          } : null,
        type: PdfType.OFFERS,
      }));
    } else {
      if (user.me?.user.profileType === ProfileType.SYNOD_ADMIN) {
        dispatch(PdfActions.create({
          ...form,
          synodId: user.me.synodId,
          date: DateTime.fromFormat(
            form.date as string,
            translate('SHARED.FORMAT_DATE')
          ).toISODate(),
          file:
          {
            id: file.uid,
            name: file?.name,
            type: 4,
            base64: file?.base64,
            extension: file?.extension,
            isPrivate: false,
          },
          type: PdfType.OFFERS,
        }));
      } else {
        dispatch(PdfActions.create({
          ...form,
          date: DateTime.fromFormat(
            form.date as string,
            translate('SHARED.FORMAT_DATE')
          ).toISODate(),
          file:
          {
            id: file.uid,
            name: file?.name,
            type: 4,
            base64: file?.base64,
            extension: file?.extension,
            isPrivate: false,
          },
          type: PdfType.OFFERS,
        }));
      }
    }
  };

  useEffect(() => {
    if (pdf && pdf.detail) {
      onFormChange('label', pdf.detail.label);
      onFormChange('date', DateTime.fromISO(pdf.detail.date as string).toLocaleString(
        DateTime.DATE_SHORT
      ));
      onFormChange('synodId', pdf.detail.synodId);
    } else {
      setForm(initialValues);
    }
  }, [pdf.detail]);

  useEffect(() => {
    dispatch(SynodActions.getAllSynods());

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(PdfActions.getDetail(params.id));
    } else {
      dispatch(PdfActions.cleanDetails());
    }
  }, [pathname, pageType]);

  return (
    <Container fluid className="details">
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.PAGE_OFFER_TITLE')}
            pageDescription={translate(`PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col>
                  <AdvancedInput
                    onChange={(value: string) => onFormChange('label', value)}
                    value={form?.label}
                    label={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.LABELS.TITLE')}
                    placeholder={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.PLACEHOLDERS.LABEL')}
                  />
                </Col>
                <Col>
                  <AdvancedInput
                    onChange={(value: string) => onFormChange('date', value)}
                    value={maskDate(form && form.date)}
                    placeholder={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.PLACEHOLDERS.DATE')}
                    label={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.LABELS.DATE')}
                  />
                </Col>
              </Row>
              {user.me?.user.profileType === ProfileType.ADMIN && form.synodId && (
                <Row>
                  <Col md={6}>
                    <AdvancedSelect
                      label={translate(
                        'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.SYNOD.LABEL'
                      )}
                      value={form.synodId}
                      onChange={(val: string) => onFormChange('synodId', val)}
                      options={synod.allSynod}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={6} className="text-center">
                  <AdvancedUploader
                    onChange={setFile}
                    hidePreview
                    isPdf
                    defaultImage={
                      file?.base64 ||
                      (form &&
                        form.file &&
                        form.file[0]?.file &&
                        form.file[0]?.file?.url)
                    }
                    value={
                      file?.base64 ||
                      (form &&
                        form.file &&
                        form.file[0]?.file &&
                        form.file[0]?.file?.url)
                    }
                  >
                    <AdvancedButton
                      text={getPagetType() === PAGE_TYPE.ADD
                        ? translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.ADD.LABELS.FILE_URL')
                        : translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.EDIT_PDF')}
                      endIcon={<CloudUploadOutlined />}
                    />
                  </AdvancedUploader>
                </Col>
                {pageType === PAGE_TYPE.EDIT && (
                  <Col lg={6}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://docs.google.com/gview?url=${pdf.detail && pdf.detail.file && pdf.detail.file[0].file.url.split('?')[0]}&embedded=true`}
                    >
                      <AdvancedButton
                        text={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.BUTTON_LINK')}
                        endIcon={<LinkOutlined />}
                      />
                    </a>
                  </Col>
                )}
                <Col lg={6}>
                  {file && (
                    <iframe src={file.base64} style={{ width: '500px', height: '400px', marginTop: '20px', marginBottom: '20px' }} title="pdf_preview" />
                  )}
                </Col>
              </Row>

              <div className="details__form__actions">
                <Row>
                  <Col md={6}>
                    <Link to={getRouteStackPath('ACTIVITIES', 'PDF_OFFER_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md={6} className="text-right">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default PDFOfferDetails;
