import { translate } from '~/services/i18n';

export const TYPE_EVENT: any = {
  NACIONAL: 1,
  SINODAL: 2,
};

export enum EventType {
  EVENT = 1,
  CAMPAIGN = 2,
  OFFERING = 3,
  REMINDER = 4,
  OCCUPATION = 5,
  OFFERING_ANNOUNCE = 6,
  MESSAGE = 7,
  COMMUNITY = 98,
  NEWS = 99
}

export const EventEnumArray: Array<{ value: number, name: string }> = [
  {
    value: EventType.EVENT,
    name: 'Evento',
  },
  {
    value: EventType.REMINDER,
    name: 'Lembrete',
  },
];

export const getEventArray = [
  { value: EventType.EVENT, i18n: 'ENUMERATORS.EVENT_TYPE.EVENT' },
  { value: EventType.CAMPAIGN, i18n: 'ENUMERATORS.EVENT_TYPE.REMIDER' },
  { value: EventType.OFFERING, i18n: 'ENUMERATORS.EVENT_TYPE.OFFERING' },
  { value: EventType.REMINDER, i18n: 'ENUMERATORS.EVENT_TYPE.REMIDER' },
  { value: EventType.MESSAGE, i18n: 'ENUMERATORS.EVENT_TYPE.MESSAGE' },
];

export const getEventTypeTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = getEventArray.find(o => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};

export enum Scope {
  SYNODAL = 1,
  NATIONAL = 2,
  PAROCHIAL = 3,
}

export const getScope = [
  {
    value: Scope.SYNODAL,
    i18n: 'ENUMERATORS.SCOPE_TYPE.SYNODAL',
    name: 'Sinodal',
  },
  {
    value: Scope.NATIONAL,
    i18n: 'ENUMERATORS.SCOPE_TYPE.NATIONAL',
    name: 'Nacional',
  },
  {
    value: Scope.PAROCHIAL,
    i18n: 'ENUMERATORS.SCOPE_TYPE.PAROCHIAL',
    name: 'Paroquial',
  },
];

export const getScopeTypeTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = getScope.find(o => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};

export const getActiveTypeTitle = (value: boolean) => {
  let response: string = '--';

  if (value) {
    response = translate('PAGES.PANEL.OFFERS.REPORT.ACTIVE_TYPE');
  }
  else {
    response = translate('PAGES.PANEL.OFFERS.REPORT.INACTIVE_TYPE');
  }

  return response;
};

export const getActiveAtMomentTypeTitle = (startsAtDate: string, endsAtDate: string) => {
  const today: Date = new Date();
  let response: string = '--';

  if (new Date(startsAtDate) <= today && new Date(endsAtDate) >= today) {
    response = translate('PAGES.PANEL.OFFERS.REPORT.ACTIVE_TYPE');
  } else {
    response = translate('PAGES.PANEL.OFFERS.REPORT.INACTIVE_TYPE');
  }

  return response;
};
