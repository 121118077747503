import AdminRequests from '~/repositories/admin';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { ADMIN_REPORT, ADMIN_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import SynodRequests from '~/repositories/synod';
import { ProfileType } from '@portal/enum/profileType';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: ADMIN_DETAIL,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.IAdminAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.User> = await AdminRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: ADMIN_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Admin = await AdminRequests.getDetail(id);

    dispatch({
      payload,
      type: ADMIN_DETAIL,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.Admin) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload = await AdminRequests.create(params);

    if (params?.synodId && params?.profileType === ProfileType.SYNOD_ADMIN) {
      await SynodRequests.associateToSynod(params?.synodId, payload.id);
    }

    MessageService.success(
      'PAGES.PANEL.USER.ADMIN.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await AdminRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.USER.ADMIN.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await AdminRequests.update(id, params);

    if (params?.synodId && params?.profileType === ProfileType.SYNOD_ADMIN) {
      await SynodRequests.associateToSynod(params?.synodId, params.id);
    }

    if (params?.synodId && params?.profileType === ProfileType.ADMIN) {
      await SynodRequests.removeFromSynod(id);
      await dispatch(cleanDetails());
    }

    MessageService.success(
      'PAGES.PANEL.USER.ADMIN.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
