import {
  CloudUploadOutlined,
  LeftOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as SynodActions from '~/actions/synod';
import * as Schedule from '@portal/actions/schedule';
import { getAllSynods } from '@portal/actions/synod';
import { getAddressByCep } from '@portal/actions/utils';
import AdvancedCheckbox from '@portal/components/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { EventEnumArray, EventType, Scope } from '@portal/enum/typeEvent';
import { useReduxState } from '@portal/hooks/useReduxState';
import * as MessageService from '@portal/services/message';
import { removeSpecialChars } from '@portal/utils/format';
import { cepMask, phoneMask } from '@portal/utils/string';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import { FileSchedule } from '~/models/module';
import { translate } from '~/services/i18n';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import { useLocation, useParams, Link } from 'react-router-dom';
import PanelContentBreadcrumb from '@portal/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { getRouteStackPath } from '@portal/config/routes';
import { DateTime } from 'luxon';
import { maskDate, maskTime, validateTime } from '@portal/services/masks';
import { ProfileType } from '@portal/enum/profileType';
import { FileType } from '@portal/enum/newsType';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';

const initialValues: models.AddSchedule = {
  name: '',
  startsAtDate: '',
  startsAtTime: '',
  endsAtDate: null,
  endsAtTime: '',
  type: EventType.EVENT,
  title: '',
  description: '',
  street: '',
  number: '',
  neighborhood: '',
  zipcode: '',
  city: '',
  state: '',
  complement: '',
  latitude: '',
  longitude: '',
  miscInfo: '',
  email: '',
  phone: '',
  synodId: null,
  mediaUrl: '',
  scope: Scope.SYNODAL,
  files: [],
};

const ScheduleDetails: React.FC = () => {
  const [form, setForm] = useState(initialValues);
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = useState(false);
  const [synodCheckBox, setSynodCheckBox] = useState(false);
  const [file, setFile] = useState<FileSchedule | null>();
  const [fileThumbnail, setFileThumbnail] = useState<FileSchedule | null>();
  const { synod, user, schedule } = useReduxState();

  const [pageType] = useState(getPagetType());
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const onFormChange = (
    key: string,
    value: string | null | number | undefined
  ) => {
    setForm((prevState: models.AddSchedule) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(getAllSynods());
  }, []);

  useEffect(() => {
    if (schedule && schedule.detail) {
      setForm(schedule.detail);
      onFormChange('zipcode', schedule.detail.address?.zipcode);
      onFormChange('city', schedule.detail.address?.city);
      onFormChange('state', schedule.detail.address?.state);
      onFormChange('street', schedule.detail.address?.street);
      onFormChange('neighborhood', schedule.detail.address?.neighborhood);
      onFormChange('longitude', schedule.detail.address?.latitude);
      onFormChange('latitude', schedule.detail.address?.longitude);
      onFormChange('number', schedule.detail.address?.number);
      onFormChange('complement', schedule.detail.address?.complement);
      if (schedule.detail.startsAtDate) {
        onFormChange(
          'startsAtDate',
          DateTime.fromISO(schedule.detail.startsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }

      if (schedule.detail.endsAtDate) {
        onFormChange(
          'endsAtDate',
          DateTime.fromISO(schedule.detail.endsAtDate as string).toLocaleString(
            DateTime.DATE_SHORT
          )
        );
      }
      onFormChange('phone', schedule.detail.phone.substring(2));

      if (schedule.detail.scope === Scope.SYNODAL) {
        setSynodCheckBox(true);
      }

      if (schedule.detail.email || (schedule.detail.phone && schedule.detail.phone !== '55')) {
        setCheckBox(true);
      }
    } else {
      setForm(initialValues);
      setCheckBox(false);
      setSynodCheckBox(false);
    }
  }, [schedule.detail]);

  useEffect(() => {
    setSynodCheckBox(false);
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(Schedule.getDetail(params.id));
    } else {
      setFileThumbnail(null);
      setFile(null);
      setCheckBox(false);
      dispatch(Schedule.cleanDetails());
    }
  }, [pathname, pageType]);

  const getAddress = (zipcode: string) => {
    dispatch(
      getAddressByCep(zipcode, (data) => {
        if (data) {
          onFormChange('neighborhood', data.data.bairro);
          onFormChange('city', data.data.localidade);
          onFormChange('street', data.data.logradouro);
          onFormChange('complement', data.data.complemento);
          onFormChange('zipcode', data.data.cep);
          onFormChange('state', data.data.uf);
        }
      })
    );
  };

  const setReportLinkYoutube = (linkYT: string) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = linkYT.match(regExp);
    let videoId = null;
    if (match && match[7].length === 11) {
      videoId = match[7];
    }
    if (linkYT.length > 24) {
      if (videoId === null) {
        return MessageService.error(
          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.ERRORS.MEDIA_URL'
        );
      }
    }
    onFormChange('mediaUrl', linkYT);
  };

  useEffect(() => {
    dispatch(SynodActions.getAllSynods());

    if (user.me?.user?.profileType === ProfileType.SYNOD_ADMIN && pageType === PAGE_TYPE.ADD) {
      onFormChange('synodId', user.me?.synod?.id);
    }

  }, [pathname, pageType, user.me?.synod]);

  const onFormSubmit = () => {
    const fileList = [];

    if (
      !DateTime.fromFormat(form.startsAtDate as string, 'dd/MM/yyyy')
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.INIT_DATE')
      );
    }

    if (form.endsAtDate) {
      if (
        !DateTime.fromFormat(form.endsAtDate as string, 'dd/MM/yyyy')
          .toJSDate()
          .getTime()
      ) {
        return MessageService.error(
          translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.END_DATE')
        );
      }
    }

    if (form?.startsAtTime) {
      if (form?.startsAtTime?.length < 5 || !validateTime(form.startsAtTime)) {
        return MessageService.error(
          translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.HOUR')
        );
      }
    }

    if (form.endsAtTime) {
      if (form.endsAtTime.length < 5 || !validateTime(form.endsAtTime)) {
        return MessageService.error(
          translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.HOUR')
        );
      }
    }

    if (form?.startsAtDate && form?.endsAtDate) {
      if (new Date(form.startsAtDate).getTime() > new Date(form.endsAtDate).getTime()) {
        return MessageService.error('APPLICATION.ERRORS.INVALID_DATE');
      }

      if (new Date(form.startsAtDate).getTime() === new Date(form.endsAtDate).getTime()) {
        if (form?.startsAtTime && form?.endsAtTime) {
          if (form.startsAtTime > form.endsAtTime) {
            return MessageService.error('APPLICATION.ERRORS.INVALID_TIME');
          }
        }
      }
    }

    if (!form.title) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.TITLE')
      );
    }

    if (!form.name) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.NAME')
      );
    }

    if (!form.type) {
      return MessageService.error(
        translate('PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.TYPE')
      );
    }

    if (!form.description) {
      return MessageService.error(
        translate(
          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.DESCRIPTION'
        )
      );
    }

    if (form.type === EventType.EVENT && pageType === PAGE_TYPE.ADD && !file) {
      return MessageService.error(
        translate(
          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.ERRORS.IMAGE'
        )
      );
    }

    if (synodCheckBox && !form.synodId) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_SYNOD_ID');
    }

    if (file) {
      fileList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            !item.file?.thumbnail)?.file?.id : null,
          name: file?.name || null,
          type: FileType.IMAGE,
          base64: file?.base64 || null,
          extension: file?.extension || null,
          isPrivate: false,
        }
      );
    }

    if (fileThumbnail) {
      fileList.push(
        {
          id: form.files ? form.files.find((item: { file: { thumbnail: boolean; } }) =>
            item.file?.thumbnail)?.file?.id : null,
          name: fileThumbnail?.name || null,
          type: FileType.IMAGE,
          base64: fileThumbnail?.base64 || null,
          extension: fileThumbnail?.extension || null,
          thumbnail: true,
          isPrivate: false,
        }
      );
    }

    const data: models.AddSchedule = {
      name: form.name,
      type: form.type,
      title: form.title,
      description: form.description,
      mediaUrl: form.mediaUrl,
      email: form.email,
      scope: !synodCheckBox ? Scope.NATIONAL : Scope.SYNODAL,
      phone: `55${removeSpecialChars(form.phone)}`,
      miscInfo: form.miscInfo,
      startsAtDate: form.startsAtDate && DateTime.fromFormat(
        form.startsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      startsAtTime: form.startsAtTime ? form.startsAtTime : undefined,
      endsAtDate: form.endsAtDate && DateTime.fromFormat(
        form.endsAtDate as string,
        translate('SHARED.FORMAT_DATE')
      ).toISODate(),
      endsAtTime: form.endsAtTime ? form.endsAtTime : undefined,
      synodId: form.synodId || user.me?.synod?.id,
      number: form.number,
      address: {
        zipcode: form.zipcode,
        street: form.street,
        neighborhood: form.neighborhood,
        city: form.city,
        state: form.state,
        number: form.number,
        latitude: form.latitude,
        longitude: form.longitude,
      },
      latitude: form.latitude,
      longitude: form.longitude,
      files: fileList,
    };

    if (form.type !== EventType.EVENT) {
      delete data.files;
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(Schedule.update(params.id, data));
    } else {
      if (user.me?.user.profileType === ProfileType.SYNOD_ADMIN) {
        dispatch(Schedule.create({ ...data, synodId: user.me?.synod?.id, scope: Scope.SYNODAL }));
      } else {
        dispatch(Schedule.create({ ...data, ...form.synodId === null && { scope: Scope.NATIONAL } }));
      }
    }
  };

  return (
    <div className="schedule-details">
      <Container fluid className="details">
        <Row>
          <Col>
            <PanelContentBreadcrumb
              items={[
                {
                  title: translate(
                    'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.TITLE'
                  ),
                  url: getRouteStackPath('ACTIVITIES', 'SCHEDULE_REPORT'),
                  active: true,
                },
                {
                  title: translate(
                    `PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                    }`
                  ),
                  active: false,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="details__title">
              <h3 className="details__title__text">
                {translate(
                  `PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </h3>
              <p className="details__title__description">
                {translate(
                  `PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                )}
              </p>
            </div>
            <AdvancedForm onSubmit={onFormSubmit}>
              <div className="details__form">
                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.TYPE_EVENT'
                    )}
                  </p>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('title', value)
                        }
                        value={form.title}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.TITLE'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.NAME_EVENT'
                        )}
                        value={form.name}
                        onChange={(val: string) => onFormChange('name', val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedSelect
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.EVENT'
                        )}
                        value={form.type}
                        onChange={(val: number | undefined) =>
                          onFormChange('type', val)
                        }
                        options={EventEnumArray}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.MEDIA_URL'
                        )}
                        value={form.mediaUrl}
                        onChange={(val: string) => {
                          onFormChange('mediaUrl', val);
                          setReportLinkYoutube(val);
                        }}
                      />
                    </Col>
                  </Row>
                </div>

                {form.type !== EventType.REMINDER && (
                  <Row>
                    < Col >
                      <label>
                        {translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.UPLOAD_THUMB'
                        )}
                      </label>
                      <AdvancedUploader
                        thumb
                        onChange={setFileThumbnail}
                        defaultImage={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          fileThumbnail?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                    <Col>
                      <label>
                        {translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.UPLOAD'
                        )}
                      </label>
                      <AdvancedUploader
                        onChange={setFile}
                        defaultImage={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                        value={
                          file?.base64 ||
                          (form &&
                            form.files &&
                            form.files.find((item: { file: { thumbnail: boolean; } }) =>
                              !item.file?.thumbnail)?.file?.url)
                        }
                      >
                        <div>
                          <AdvancedButton
                            className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                            text={translate(
                              'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD'
                            )}
                            endIcon={<CloudUploadOutlined />}
                          />
                        </div>
                      </AdvancedUploader>
                    </Col>
                  </Row>
                )}

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.TITLE_DATE'
                    )}
                  </p>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('startsAtDate', value)
                        }
                        value={maskDate(form && form.startsAtDate)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DATE_BEGIN'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string) =>
                          onFormChange('endsAtDate', value)
                        }
                        value={maskDate(form && form.endsAtDate)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_DATE'
                        )}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DATE_END'
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.TIME_BEGIN'
                        )}
                        onChange={(value: string) =>
                          onFormChange('startsAtTime', value)
                        }
                        maxLength={5}
                        value={maskTime(form && form.startsAtTime)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_TIME'
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.TIME_END'
                        )}
                        onChange={(value: string) =>
                          onFormChange('endsAtTime', value)
                        }
                        maxLength={5}
                        value={maskTime(form && form.endsAtTime)}
                        placeholder={translate(
                          'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.DETAILS.PLACEHOLDER_TIME'
                        )}
                      />
                    </Col>
                  </Row>
                </div>
                
                <div className="details__title">
                  {user.me?.user.profileType === ProfileType.ADMIN && (
                    <div>
                      <Row>
                        <Col md={6}>
                          <AdvancedCheckbox
                            label={translate(
                              'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.ADD_SYNOD'
                            )}
                            value={synodCheckBox}
                            onChange={() => setSynodCheckBox(!synodCheckBox)}
                          />
                        </Col>
                        <Col md={6}>
                          {synodCheckBox && (
                            <AdvancedSelect
                              label={translate(
                                'PAGES.PANEL.MESSAGES_DETAILS.REPORT.ADD.LABELS.SYNOD'
                              )}
                              value={form.synodId}
                              onChange={(val: string | undefined) =>
                                onFormChange('synodId', val)
                              }
                              options={synod.allSynod}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DESCRIPTION'
                    )}
                  </p>
                  <Row>
                    <Col md={12}>
                      <AdvancedTextInput
                        id="schedule-form"
                        value={form.description}
                        onChange={(val: string) => onFormChange('description', val)}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.ADDRESS'
                    )}
                  </p>
                  <Row>
                    <Col md={4}>
                      <AdvancedInput
                        onChange={(value: string | null) => {
                          onFormChange('zipcode', value);
                          if (
                            value &&
                            removeSpecialChars(value)?.length === 8
                          ) {
                            getAddress(value);
                          }
                        }}
                        value={cepMask(form && form.zipcode)}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.ZIPCODE'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('city', value)
                        }
                        value={form.city}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.CITY'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('state', value)
                        }
                        value={form.state}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.STATE'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('street', value)
                        }
                        value={form.street}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.STREET'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('number', value)
                        }
                        value={form.number}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.NUMBER'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('neighborhood', value)
                        }
                        value={form.neighborhood}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.NEIGHBORHOOD'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('complement', value)
                        }
                        value={form.complement}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.COMPLEMENT'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('latitude', value)
                        }
                        value={form.latitude}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.LAT'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                    <Col md={6}>
                      <AdvancedInput
                        onChange={(value: string | null) =>
                          onFormChange('longitude', value)
                        }
                        value={form.longitude}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.LONG'
                        )}
                        disabled={form.type === EventType.REMINDER}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="details__title">
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.MISCINFO'
                    )}
                  </p>
                  <Row>
                    <Col md={12}>
                      <AdvancedTextInput
                        id="schedule-form2"
                        value={form.miscInfo}
                        onChange={(val: string) => onFormChange('miscInfo', val)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <AdvancedCheckbox
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.ADD_SUPORT_INFO'
                        )}
                        onChange={() => setCheckBox(!checkBox)}
                        value={checkBox}
                      />
                    </Col>
                  </Row>
                  {checkBox && (
                    <Row>
                      <Col lg={8}>
                        <AdvancedInput
                          onChange={(value: string | null) =>
                            onFormChange('email', value)
                          }
                          value={form.email}
                          label={translate(
                            'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.EMAIL'
                          )}
                        />
                      </Col>
                      <Col lg={4}>
                        <AdvancedInput
                          onChange={(value: string) =>
                            onFormChange('phone', value)
                          }
                          value={phoneMask(form && form.phone)}
                          label={translate(
                            'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.PHONE'
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                </div>

                <div className="details__form__actions">
                  <Row>
                    <Col md={6}>
                      <Link
                        to={getRouteStackPath('ACTIVITIES', 'SCHEDULE_REPORT')}
                      >
                        <AdvancedButton
                          variant="text"
                          text={translate('SHARED.BACK')}
                          startIcon={<LeftOutlined />}
                        />
                      </Link>
                    </Col>
                    <Col md={6} className="text-right">
                      <AdvancedButton
                        type="submit"
                        text={translate('SHARED.SAVE')}
                        endIcon={<PlusCircleOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </AdvancedForm>
          </Col>
        </Row>
      </Container>
    </div >
  );
};

export default ScheduleDetails;
