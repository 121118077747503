import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import NotificationsDetails from './Details/NotificationsDetails';
import NotificationsReport from './Report/NotificationsReport';

const NotificationsNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ACTIVITIES', 'NOTIFICATIONS_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_TITLE')}
          pageDescription={translate('PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_DESCRIPTION')}
        >
          <NotificationsReport />
        </PanelContent>
      </Route>
      <Route path={getRouteStackPath('ACTIVITIES', 'NOTIFICATIONS_DETAILS')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_TITLE')}
          pageDescription={translate('PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_DESCRIPTION')}
        >
          <NotificationsDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default NotificationsNavigationStack;
