import { RightCircleOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { getDetail, update } from '@portal/actions/synod';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import { translate } from '~/services/i18n';
import { FileSchedule, FileItemSynod } from '~/models/module';
import * as FileActions from '~/actions/file';
import AdvancedForm from '../AdvancedForm/AdvancedForm';
import AdvancedInput from '../AdvancedInput/AdvancedInput';
import AdvancedTextInput from '../AdvancedTextInput/AdvancedTextInput';
import { useReduxState } from '@portal/hooks/useReduxState';

interface IMySynodForm {
  url?: boolean;
  title?: boolean;
  description?: boolean;
  otherInfo?: boolean;
  sender: 'init' | 'lead' | 'occup';
}

const MySynodForm = ({
  title,
  description,
  otherInfo,
  url,
  sender,
}: IMySynodForm) => {
  const dispatch = useDispatch();
  const { user, synod } = useReduxState();

  const synodData: any = synod.detail;

  const [formUrl, setUrl] = useState('');
  const [formTitle, setTitle] = useState('');
  const [formDescription, setDescription] = useState('');
  const [formOtherInfo, setOtherInfo] = useState('');
  const [councilDescription, setCouncilDescription] = useState('');
  const [boardDescription, setBoardDescription] = useState('');
  const [image, setImage] = useState<FileItemSynod>();
  
  const onFormSubmit = async () => {

    let shareUrl;

    if (image) {
      shareUrl = await dispatch(FileActions.create(
        {
          type: 1,
          extension: image.extension,
          name: image.name,
          base64: image.base64,
          isPrivate: false,
        }
      ));
    }

    if (synodData) {
      if (sender === 'init') {
        dispatch(
          update(synodData.id, {
            videoUrl: formUrl,
            initTitle: formTitle,
            initDescription: formDescription,
            moreInfo: formOtherInfo,
            imageUrl: shareUrl,
          })
        );
      } else if (sender === 'lead') {
        dispatch(
          update(synodData.id, {
            leadershipTitle: formTitle,
            leadershipDescription: formDescription,
            boardDescription,
            councilDescription,
          })
        );
      } else if (sender === 'occup') {
        dispatch(
          update(synodData.id, {
            occupAreaTitle: formTitle,
            occupAreaDescription: formDescription,
          })
        );
      }
    }
  };

  const setValues = () => {
    if (synodData) {
      if (sender === 'init') {
        setTitle(synodData.initTitle);
        setDescription(synodData.initDescription);
        setUrl(synodData.videoUrl);
        setOtherInfo(synodData.moreInfo);
      } else if (sender === 'lead') {
        setTitle(synodData.leadershipTitle);
        setDescription(synodData.leadershipDescription);
        setCouncilDescription(synodData.councilDescription);
        setBoardDescription(synodData.boardDescription);
      } else if (sender === 'occup') {
        setTitle(synodData.occupAreaTitle);
        setDescription(synodData.occupAreaDescription);
      }
    }
  };

  useEffect(() => {
    if (user.me) {
      dispatch(getDetail(user.me.synod?.id));
    }
  }, [user.me]);

  useEffect(() => {
    setValues();
  }, [synod.detail]);

  return (
    <div className="my-synod-form">
      <div className="my-synod-form__items">
        <AdvancedForm onSubmit={onFormSubmit}>
          <div className="my-synod-form__items__item my-synod-form__items__item--add">
            <Row>
              <Col lg={12}>
                <h4>
                  {translate('PAGES.PANEL.USER.ADMIN.DETAILS.TITLE_SYNOD_IMAGE')}
                </h4>
                <AdvancedUploader
                  onChange={(value) => setImage(value)}
                  value={(synodData && synodData.imageUrl) || (image && image.base64)}
                  thumb
                >
                  <AdvancedButton
                    text={translate('PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.WHERE_ARE_WE.BUTTON_UPLOAD')}
                    endIcon={<UploadOutlined />}
                  />
                </AdvancedUploader>
              </Col>
            </Row>
            <br/>
            {url && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={setUrl}
                    value={formUrl}
                    label={translate('PAGES.PANEL.MY_SYNOD.FORM.URL')}
                  />
                </Col>
              </Row>
            )}
            {title && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={setTitle}
                    value={formTitle}
                    label={translate('PAGES.PANEL.MY_SYNOD.FORM.TITLE')}
                  />
                </Col>
              </Row>
            )}
            {description && (
              <Row>
                <Col lg={12}>
                  <br/>
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.MY_SYNOD.FORM.DESCRIPTION'
                    )}
                  </p>
                  <AdvancedTextInput
                    id="mysynod-form"
                    value={formDescription}
                    onChange={setDescription}
                  />
                </Col>
              </Row>
            )}
            {otherInfo && (
              <Row>
                <Col lg={12}>
                  <br/>
                  <p className="details__title__description">
                    {translate(
                      'PAGES.PANEL.MY_SYNOD.FORM.OTHER_INFO'
                    )}
                  </p>
                  <AdvancedTextInput
                    id="mysynod-form2"
                    value={formOtherInfo}
                    onChange={setOtherInfo}
                  />
                </Col>
              </Row>
            )}
            {
              sender === 'lead' && (
                <>
                  <Row>
                    <Col md={6}>
                      <div className="details__form__subtitle">
                        <h3>{translate('ENUMERATORS.LEADER_ROLE.BOARD.LABEL')}</h3>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AdvancedTextInput
                        id="form-lead-board"
                        value={councilDescription}
                        onChange={setCouncilDescription}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="details__form__subtitle">
                        <h3>{translate('ENUMERATORS.LEADER_ROLE.COUNCIL.LABEL')}</h3>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AdvancedTextInput
                        id="form-lead-council"
                        value={boardDescription}
                        onChange={setBoardDescription}
                      />
                    </Col>
                  </Row>
                </>
              )
            }
            <Row>
              <Col lg={12} className="text-right">
                <AdvancedButton
                  className="my-synod-form__items__item__button my-synod-form__items__item--add"
                  type="submit"
                  text={translate('PAGES.PANEL.INSTITUTIONAL.FORM.BUTTON')}
                  endIcon={<RightCircleOutlined />}
                />
              </Col>
            </Row>
          </div>
        </AdvancedForm>
      </div>
    </div>
  );
};

export default MySynodForm;
