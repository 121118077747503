export enum BankNames {
  AGIBANK = "Agibank",
  BNP_PARIBAS_BRASIL = "BNP Paribas Brasil",
  BRB = "BRB",
  BS2 = "BS2",
  BANCO_C6 = "Banco C6",
  BANCO_DAYCOVAL = "Banco Daycoval",
  BANCO_ORIGINAL = "Banco Original",
  BANCO_TOPAZIO = "Banco Topazio",
  BANCO_DA_AMAZONIA = "Banco da Amazonia",
  BANCO_DO_BRASIL = "Banco do Brasil",
  BANCO_DO_NORDESTE = "Banco do Nordeste",
  BANESTES = "Banestes",
  BANPARA = "Banpar\u00E1",
  BANRISUL = "Banrisul",
  BRADESCO = "Bradesco",
  CAIXA_ECONOMICA = "Caixa Econ\u00F4mica",
  CAIXA_ECONOMICA_CP = "Caixa Econ\u00F4mica CP",
  CITIBANK = "Citibank",
  COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO = "Cooperativa Central de Credito Noroeste Brasileiro",
  CORA = "Cora",
  GERENCIANET_PAGAMENTOS_DO_BRASIL = "Gerencianet Pagamentos do Brasil",
  GLOBAL_SCM = "Global SCM",
  INTER = "Inter",
  ITAU = "Ita\u00FA",
  JP_MORGAN = "JP Morgan",
  JUNO = "Juno",
  MERCADO_PAGO = "Mercado Pago",
  MERCANTIL_DO_BRASIL = "Mercantil do Brasil",
  MODAL = "Modal",
  MONEY_PLUS = "Money Plus",
  NEON = "Neon",
  NEXT = "Next",
  NUBANK = "Nubank",
  PJBANK = "PJBank",
  PAGSEGURO = "PagSeguro",
  RENDIMENTO = "Rendimento",
  SAFRA = "Safra",
  SANTANDER = "Santander",
  SICOOB = "Sicoob",
  SICREDI = "Sicredi",
  STONE = "Stone",
  UNICRED = "Unicred",
  UNIPRIME = "Uniprime",
  UNIPRIME_NORTE_DO_PARANA = "Uniprime Norte do Paran\u00E1",
  VIA_CREDI = "Via Credi",
  VOTORANTIM = "Votorantim"
}

export enum BankAccountType {
  SAVINGS = 1,
  CHECKING = 2
}

export const bankAccountTypeArray = [
  {
    value: BankAccountType.SAVINGS,
    name: 'POUPANÇA',
  },
  {
    value: BankAccountType.CHECKING,
    name: 'CORRENTE',
  },
];

export const bankTypeArray = [
  {
    value: BankNames.AGIBANK,
    name: BankNames.AGIBANK,
  },
  {
    value: BankNames.BNP_PARIBAS_BRASIL,
    name: BankNames.BNP_PARIBAS_BRASIL,
  },
  {
    value: BankNames.BRB,
    name: BankNames.BRB,
  },
  {
    value: BankNames.BS2,
    name: BankNames.BS2,
  },
  {
    value: BankNames.BANCO_C6,
    name: BankNames.BANCO_C6,
  },
  {
    value: BankNames.BANCO_DAYCOVAL,
    name: BankNames.BANCO_DAYCOVAL,
  },
  {
    value: BankNames.BANCO_ORIGINAL,
    name: BankNames.BANCO_ORIGINAL,
  },
  {
    value: BankNames.BANCO_TOPAZIO,
    name: BankNames.BANCO_TOPAZIO,
  },
  {
    value: BankNames.BANCO_DA_AMAZONIA,
    name: BankNames.BANCO_DA_AMAZONIA,
  },
  {
    value: BankNames.BANCO_DO_BRASIL,
    name: BankNames.BANCO_DO_BRASIL,
  },
  {
    value: BankNames.BANCO_DO_NORDESTE,
    name: BankNames.BANCO_DO_NORDESTE,
  },
  {
    value: BankNames.BANESTES,
    name: BankNames.BANESTES,
  },
  {
    value: BankNames.BANPARA,
    name: BankNames.BANPARA,
  },
  {
    value: BankNames.BANRISUL,
    name: BankNames.BANRISUL,
  },
  {
    value: BankNames.BRADESCO,
    name: BankNames.BRADESCO,
  },
  {
    value: BankNames.CAIXA_ECONOMICA,
    name: BankNames.CAIXA_ECONOMICA,
  },
  {
    value: BankNames.CAIXA_ECONOMICA_CP,
    name: BankNames.CAIXA_ECONOMICA_CP,
  },
  {
    value: BankNames.CITIBANK,
    name: BankNames.CITIBANK,
  },
  {
    value: BankNames.COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO,
    name: BankNames.COOPERATIVA_CENTRAL_DE_CREDITO_NOROESTE_BRASILEIRO,
  },
  {
    value: BankNames.CORA,
    name: BankNames.CORA,
  },
  {
    value: BankNames.GERENCIANET_PAGAMENTOS_DO_BRASIL,
    name: BankNames.GERENCIANET_PAGAMENTOS_DO_BRASIL,
  },
  {
    value: BankNames.GLOBAL_SCM,
    name: BankNames.GLOBAL_SCM,
  },
  {
    value: BankNames.INTER,
    name: BankNames.INTER,
  },
  {
    value: BankNames.ITAU,
    name: BankNames.ITAU,
  },
  {
    value: BankNames.JP_MORGAN,
    name: BankNames.JP_MORGAN,
  },
  {
    value: BankNames.JUNO,
    name: BankNames.JUNO,
  },
  {
    value: BankNames.MERCADO_PAGO,
    name: BankNames.MERCADO_PAGO,
  },
  {
    value: BankNames.MERCANTIL_DO_BRASIL,
    name: BankNames.MERCANTIL_DO_BRASIL,
  },
  {
    value: BankNames.MODAL,
    name: BankNames.MODAL,
  },
  {
    value: BankNames.MONEY_PLUS,
    name: BankNames.MONEY_PLUS,
  },
  {
    value: BankNames.NEON,
    name: BankNames.NEON,
  },
  {
    value: BankNames.NEXT,
    name: BankNames.NEXT,
  },
  {
    value: BankNames.NUBANK,
    name: BankNames.NUBANK,
  },
  {
    value: BankNames.PJBANK,
    name: BankNames.PJBANK,
  },
  {
    value: BankNames.PAGSEGURO,
    name: BankNames.PAGSEGURO,
  },
  {
    value: BankNames.RENDIMENTO,
    name: BankNames.RENDIMENTO,
  },
  {
    value: BankNames.SAFRA,
    name: BankNames.SAFRA,
  },
  {
    value: BankNames.SANTANDER,
    name: BankNames.SANTANDER,
  },
  {
    value: BankNames.SICOOB,
    name: BankNames.SICOOB,
  },
  {
    value: BankNames.SICREDI,
    name: BankNames.SICREDI,
  },
  {
    value: BankNames.STONE,
    name: BankNames.STONE,
  },
  {
    value: BankNames.UNICRED,
    name: BankNames.UNICRED,
  },
  {
    value: BankNames.UNIPRIME,
    name: BankNames.UNIPRIME,
  },
  {
    value: BankNames.UNIPRIME_NORTE_DO_PARANA,
    name: BankNames.UNIPRIME_NORTE_DO_PARANA,
  },
  {
    value: BankNames.VIA_CREDI,
    name: BankNames.VIA_CREDI,
  },
  {
    value: BankNames.VOTORANTIM,
    name: BankNames.VOTORANTIM,
  },
];