import getInstance from './instance';
import { REC_PASSWORD_API_URL } from '@portal/config/env';

const PasswordRecoveryApi = {
  sendVerification: async (email: string) => {
    const instance = await getInstance();

    const { data } = await instance.post(`${REC_PASSWORD_API_URL}/user/password-recovery`, {
      email,
      sendTo: 'email',
    });

    return data;
  },

  putRecovery: async (params: models.ResetPassword) => {
    const instance = await getInstance();
    const { data } = await instance.put(
      `${REC_PASSWORD_API_URL}/user/password-recovery`,
      params
    );

    return data;
  },
};

export default PasswordRecoveryApi;
