import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';
import { renderOnlyTextFromHtml } from '../../../../utils/string'
import * as AnnouncementActions from '~/actions/announcement';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.IAnnouncementAdvancedFilter = {
  title: null,
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  fileUrl: null,
  description: null,
  date: null,
  type: 1,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'title',
    placeholder: 'Título',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'date',
    placeholder: 'Data',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const AnnouncementReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { announcement } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
      type: 1,
    });
  }, []);

  const onSearch = (
    filters: advancedFilterModels.IAnnouncementAdvancedFilter
  ) => {
    dispatch(AnnouncementActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(AnnouncementActions.remove(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
      type: 1,
    });
  };

  return (
    <div className="report">
      <Row>
        <Col>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.PASSWORDS.REPORT.TITLE')}
            pageDescription={translate('PAGES.PANEL.PASSWORDS.REPORT.DESCRIPTION')}
          />
        </Col>
        <Col lg={6} className="text-right">
          <Link
            to={`${getRouteStackPath('ACTIVITIES', 'PASSWORDS_DETAILS')}/${translate(
              'SHARED.ADD_ID'
            )}`}
          >
            <AdvancedButton
              text={translate('SHARED.ADD_NEW')}
              endIcon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              onSearch({ ...filtersData, type: 1 })
              setAdvancedFilters(filtersData)
            }}
          />
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col lg={12}>
            <div className="report__table__inner">
              <DataTable
                rows={announcement?.list?.rows || []}
                rowCount={announcement?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADVANCED_FILTER.TITLE_FIELD'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADVANCED_FILTER.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADVANCED_FILTER.DESCRIPTION_FIELD'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADD.LABELS.DESCRIPTION'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {renderOnlyTextFromHtml(o.row.description)}
                        </>
                      )
                    }
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADVANCED_FILTER.DATE_FIELD'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADD.LABELS.DATE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATE_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.PASSWORDS.REPORT.ADVANCED_FILTER.ACTIONS'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.PASSWORDS.LABELS.ACTIONS'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath('ACTIVITIES', 'PASSWORDS_DETAILS')}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    type: 1,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AnnouncementReport;
