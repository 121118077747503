import React, { useState } from "react"
import { openPdf } from '@portal/utils/fileUtils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import { translate } from '~/services/i18n';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import { Divider } from 'antd';
import { DownloadOutlined, DeleteOutlined, DragOutlined } from '@ant-design/icons';
import Button from '@material-ui/core/Button';
import { Modal } from 'antd';

interface Props {
  fileList: models.FileItem[];
  setFileList: React.Dispatch<React.SetStateAction<models.FileItem[]>>;
  setFileToExclude: React.Dispatch<React.SetStateAction<models.FileItem>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
  handleOk: () => Promise<void>;
  handleCancel: () => void;
}

const AdvancedDrag: React.FC<Props> = (props: Props) => {

  const changeFileTitle = (value: string, index: number, item: any) => {
    item = {
      ...item,
      title: value,
      name: item.name || ''
    }
    const items = Array.from(props.fileList);
    items.splice(index, 1);
    items.splice(index, 0, item);
    props.setFileList(items)
  }

  const handleDrag = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(props.fileList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    props.setFileList(items)
  }

  return (
    <div>
      <Modal
        visible={props.modalOpen}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
      >
        <h3>
          {translate('APPLICATION.ERRORS.EXCLUDE_PDF')}
        </h3>
      </Modal>
      {props.fileList && props.fileList.length > 0 && (
        <h3 className="details__title__description">
          {translate(
            'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.PDF_ORDER_INFO'
          )}
        </h3>
      )}
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="campaign">
          {(provided: any) => (
            <Col {...provided.dropableProps} ref={provided.innerRef}>
              {props.fileList && props.fileList.length > 0 && props.fileList.map((item:any, index:any) => (
                <Draggable key={item.id || item.uid} draggableId={item.id || item.uid} index={index}>
                  {(provided) => (
                    <Row {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                      <Divider style={{ backgroundColor: '#A70228' }} />
                      <br />
                      <Col md={1}>
                        <DragOutlined style={{ fontSize: '1.5em', marginTop: '1rem' }} />
                      </Col>
                      <Col md={3}>
                        <AdvancedInput
                          onChange={(value: string) =>
                            changeFileTitle(value, index, item)
                          }
                          value={props.fileList[index].title || ''}
                          label={translate(
                            'PAGES.PANEL.CAMPAIGNS_DETAILS.REPORT.ADD.LABELS.PDF_NAME'
                          )}
                        />
                      </Col>
                      <br />
                      <Col>
                        <p>{props.fileList[index].name}</p>
                      </Col>
                      <Col md={1}>
                        <Button
                          variant="contained"
                          onClick={() => openPdf(item.url ? item.url : item.base64)}
                          color="primary"
                        >
                          <DownloadOutlined />
                        </Button>
                      </Col>
                      <Col md={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            props.setModalOpen(true)
                            props.setFileToExclude(item)
                          }}
                          color="primary"
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                      <Divider style={{ backgroundColor: '#A70228' }} />
                    </Row>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Col>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default AdvancedDrag