import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import CultDetails from './Details/CultDetails';
import CultReport from './Report/CultReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const CultNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('WORSHIP', 'CULT_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.CULT.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.CULT.REPORT.DESCRIPTION')}
        >
          <CultReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('WORSHIP', 'CULT_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.CULT.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.CULT.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <CultDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default CultNavigationStack;
