import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';
import CategoryNavigationStack from './Category/CategoryNavigationStack';
import SubCategoryNavigationStack from './SubCategory/SubCategoryNavigationStack';

const OffersNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('OCCUPATION_AREA')}>
        <CategoryNavigationStack />
        <SubCategoryNavigationStack />
      </Route>
    </Switch>
  );
};

export default OffersNavigationStack;
