import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import MessagesDetails from './Details/MessagesDetails';
import MessagesReport from './Report/MessagesReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const MessagesNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ACTIVITIES', 'MESSAGES_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.MESSAGES.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.MESSAGES.REPORT.DESCRIPTION')}
        >
          <MessagesReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('ACTIVITIES', 'MESSAGES_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.MESSAGES.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.MESSAGES.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <MessagesDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default MessagesNavigationStack;
