import { WORSHIP_CONTENT_REPORT, WORSHIP_CONTENT_DETAIL } from "@portal/actions/actionTypes";

const initialState: reducers.WorshipContentReducer = {
  list: null,
  detail: null,
};

const worshipContentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case WORSHIP_CONTENT_REPORT:
      return {
        ...state,
        list: action.payload
      };

    case WORSHIP_CONTENT_DETAIL:
      return {
        ...state,
        detail: action.payload
      };

    default:
      return state;
  }
};

export default worshipContentReducer;