/* tslint:disable:object-literal-sort-keys */
import { USER_PAGE_TYPE } from '~/enum/pageType';

const routes: models.route[] = [
  {
    id: 'DASHBOARD',
    name: 'PAGES.PANEL.DASHBOARD.SIDEBAR_TITLE',
    route: '/dashboard',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'DETAILS',
        name: 'Informações Gerais',
        route: '/detalhes',
      },
    ],
  },
  {
    id: 'USER',
    name: 'PAGES.PANEL.USER.SIDEBAR_TITLE',
    route: '/usuarios',
    icon: '/assets/svg/panel-sidebar/ic_users.svg',
    iconAlt: 'Usuários',
    items: [
      {
        id: 'ADMIN_REPORT',
        name: 'Administradores',
        route: '/administradores/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'ADMIN_DETAILS',
        name: 'Detalhes',
        route: '/administradores/detalhes',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },

      {
        id: 'USER_REPORT',
        name: 'Usuários',
        route: '/usuarios/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'USER_DETAILS',
        name: 'Detalhes',
        route: '/usuarios/detalhes',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },
      {
        id: 'SYNOD_REPORT',
        name: 'Sínodos',
        route: '/sinodos/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'SYNOD_DETAILS',
        name: 'Sínodos',
        route: '/sinodos/detalhes',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'PARISH_REPORT',
        name: 'Paróquias',
        route: '/paroquias/lista',
      },
      {
        id: 'PARISH_DETAILS',
        name: 'Paróquias',
        route: '/paroquias/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'LEADER_REPORT',
        name: 'Lideranças',
        route: '/liderancas/lista',
      },
      {
        id: 'LEADER_DETAILS',
        name: 'liderança',
        route: '/liderancas/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'COMMUNITY',
        name: 'Comunidades',
        route: '/comunidade/lista',
      },
      {
        id: 'COMMUNITY_REPORT',
        name: 'Comunidades',
        route: '/comunidade/lista',
        sidebarHidden: true,
      },
      {
        id: 'COMMUNITY_DETAILS',
        name: 'Comunidades',
        route: '/comunidade/detalhes',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'MEU_SINODO',
    name: 'MEU SÍNODO',
    route: '/meu-sinodo',
    icon: '/assets/svg/panel-sidebar/ic_my_synod.svg',
    iconAlt: 'Meu Sínodo',
    accessType: USER_PAGE_TYPE.USER,
    items: [
      {
        id: 'INITIAL',
        name: 'Início',
        route: '/inicio',
      },
      {
        id: 'LEADERS',
        name: 'Lideranças',
        route: '/liderancas',
      },
      {
        id: 'OCCUPATION_AREA',
        name: 'Áreas de atuação',
        route: '/areas-de-atuacao',
      },
    ],
  },
  {
    id: 'INSTITUCIONAL',
    name: 'INSTITUCIONAL',
    route: '/institucional',
    icon: '/assets/svg/panel-sidebar/ic_enterprise.svg',
    iconAlt: 'Institucional',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'IECLB',
        name: 'IECLB',
        route: '/ieclb',
      },
      {
        id: 'ORGANIZATION',
        name: 'Organização',
        route: '/organizacao',
      },
      {
        id: 'ECUMENISM',
        name: 'Ecumenismo',
        route: '/ecumenismo',
      },
      {
        id: 'INSTITUTION',
        name: 'Instituições',
        route: '/instituicoes',
      },
      {
        id: 'DETALHES',
        name: 'Detalhes',
        route: '/detalhes',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'ACTIVITIES',
    name: 'PAGES.PANEL.NEW_ACTIVITIES.SIDEBAR_TITLE',
    route: '/atividades',
    icon: '/assets/svg/panel-sidebar/ic_activities.svg',
    iconAlt: 'Atividades',
    items: [
      {
        id: 'SCHEDULE_REPORT',
        name: 'Agendas & Eventos',
        route: '/agenda/lista',
      },
      {
        id: 'SCHEDULE_DETAILS',
        name: 'Agendas & Eventos',
        route: '/agenda/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'PDF_REPORT',
        name: 'PDF da agenda',
        route: '/agenda/pdf/lista',
      },
      {
        id: 'PDF_DETAILS',
        name: 'Agendas & Eventos',
        route: '/agenda/pdf/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'PDF_OFFER_REPORT',
        name: 'PDF da oferta',
        route: '/agenda/pdf-oferta/lista',
      },
      {
        id: 'PDF_OFFER_DETAILS',
        name: 'Agendas & Eventos',
        route: '/agenda/pdf-oferta/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'PDF_SINOD_REPORT',
        name: 'PDF de Sinodo',
        route: '/agenda/pdf-sinodo/lista',
      },
      {
        id: 'PDF_SINOD_DETAILS',
        name: 'Agendas & Eventos',
        route: '/agenda/pdf-sinodo/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'NEWS',
        name: 'Notícias',
        route: '/noticia/lista',
      },
      {
        id: 'NEWS_REPORT',
        name: 'Notícias',
        route: '/noticia/lista',
        sidebarHidden: true,
      },
      {
        id: 'NEWS_DETAILS',
        name: 'Notícias',
        route: '/noticia/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'NEWS_ADD',
        name: 'Notícias',
        route: '/noticia/detalhes/add',
        sidebarHidden: true,
      },
      {
        id: 'PASSWORDS_REPORT',
        name: 'Senhas Diárias',
        route: '/senhas-diarias/lista',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'PASSWORDS_DETAILS',
        name: 'Senhas Diárias',
        route: '/senhas-diarias/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'NOTIFICATIONS',
        name: 'Notificações',
        route: '/notificacoes/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'NOTIFICATIONS_REPORT',
        name: 'Senhas Diárias',
        route: '/notificacoes/lista',
        sidebarHidden: true,
      },
      {
        id: 'NOTIFICATIONS_DETAILS',
        name: 'Senhas Diárias',
        route: '/notificacoes/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'CALLTOACTION',
        name: 'Botões de Ação',
        route: '/call-to-action/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'CALLTOACTION_DETAILS',
        name: 'Botões de Ação',
        route: '/call-to-action/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'CALLTOACTION_REPORT',
        name: 'Botões de Ação',
        route: '/call-to-action/lista',
        sidebarHidden: true,
      },
      {
        id: 'CAMPAIGNS',
        name: 'Campanhas',
        route: '/campanhas/lista',
      },
      {
        id: 'CAMPAIGNS_REPORT',
        name: 'Campanhas',
        route: '/campanhas/lista',
        sidebarHidden: true,
      },
      {
        id: 'CAMPAIGNS_DETAILS',
        name: 'Campanhas',
        route: '/campanhas/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'MESSAGES',
        name: 'Mensagens',
        route: '/mensagens/lista',
      },
      {
        id: 'MESSAGES_REPORT',
        name: 'Mensagens',
        route: '/mensagens/lista',
        sidebarHidden: true,
      },
      {
        id: 'MESSAGES_DETAILS',
        name: 'Mensagens',
        route: '/mensagens/detalhes',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'OCCUPATION_AREA',
    name: 'PAGES.PANEL.OCCUPATION_AREA.ROUTE.NAME',
    route: '/areas-de-atuacao',
    icon: '/assets/svg/panel-sidebar/ic_activities.svg',
    iconAlt: 'Áreas de atuação',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'CATEGORY_REPORT',
        name: 'Categorias',
        route: '/categorias/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'CATEGORY_DETAILS',
        name: 'Categorias',
        route: '/categorias/detalhes',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },
      {
        id: 'SUBCATEGORY_REPORT',
        name: 'Subcategorias',
        route: '/subcategorias/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'SUBCATEGORY_DETAILS',
        name: 'Subcategorias',
        route: '/subcategorias/detalhes',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },
    ]
  },
  {
    id: 'OFFERS',
    name: 'PAGES.PANEL.NEW_OFFERS.SIDEBAR_TITLE',
    route: '/ofertas',
    icon: '/assets/svg/panel-sidebar/ic_activities.svg',
    iconAlt: 'Ofertas',
    items: [
      {
        id: 'OFFERS_REPORT',
        name: 'Ofertas cadastradas',
        route: '/lista',
      },
      {
        id: 'OFFERS_DETAILS',
        name: 'Ofertas',
        route: '/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'OFFERS_RECEIVED_REPORT',
        name: 'Ofertas recebidas',
        route: '/ofertas-recebidas/lista',
      },
      {
        id: 'OFFERS_RECEIVED_DETAILS',
        name: 'Ofertas',
        route: '/ofertas-recebidas/detalhes',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'PASSWORDS',
    name: 'SENHAS',
    route: '/atividades',
    icon: '/assets/svg/panel-sidebar/ic_passwords.svg',
    iconAlt: 'Senhas',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'ABOUT_PASSWORDS',
        name: 'Sobre as senhas diárias',
        route: '/sobre-senhas-diarias',
      },
      {
        id: 'PASSWORDS',
        name: 'Senhas Diárias',
        route: '/senhas-diarias/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
    ],
  },
  {
    id: 'WORSHIP',
    name: 'CULTO & ORAÇÃO',
    route: '/culto-oracao',
    icon: '/assets/svg/panel-sidebar/ic_worship.svg',
    iconAlt: 'Culto',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'CULT_REPORT',
        name: 'Cultos',
        route: '/culto/lista',
      },
      {
        id: 'CULT_DETAILS',
        name: 'Cultos',
        route: '/culto/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'LITURGY_REPORT',
        name: 'Liturgias',
        route: '/liturgia/lista',
      },
      {
        id: 'LITURGY_DETAILS',
        name: 'Liturgias',
        route: '/liturgia/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'PRAYER_REPORT',
        name: 'Orações',
        route: '/oracao/lista',
      },
      {
        id: 'PRAYER_DETAILS',
        name: 'Orações',
        route: '/oracao/detalhes',
        sidebarHidden: true,
      },
    ],
  },
];

export const getRoutes = (): models.route[] => routes;

export const getRoutestack = (route: string): models.route =>
  routes.find((o) => o.route === route) as models.route;

export const getStackPath = (stackId: string): string => {
  return `${routes.find((o) => o.id === stackId)?.route}`;
};

export const routeExist = (route: string): boolean => {
  const routeTop = routes.find((o) => route.startsWith(o.route));

  if (!routeTop) {
    return false;
  }
  if (routeTop.route.length === route.length) {
    return false;
  }

  return (
    (routeTop.items.find((o) => `${routeTop.route}${o.route}` === route) &&
      true) ||
    false
  );
};

export const getRouteStackPath = (stackId: string, routeId: string): string => {
  const route = routes.find((o) => o.id === stackId);

  return `${route?.route}${route?.items.find((o) => o.id === routeId)?.route}`;
};
