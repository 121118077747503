import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';

import * as SynodActions from '~/actions/synod';
import * as StateActions from '~/actions/state';

import { getRouteStackPath } from '~/config/routes';

import { getPagetType } from '~/utils/page';

import { PAGE_TYPE } from '~/enum/pageType';

import { useReduxState } from '~/hooks/useReduxState';
import AdvancedSelect from '@portal/components/AdvancedSelect/AdvancedSelect';
import { IBaseFilter } from '@portal/models/advanced-filters';
import AdvancedCheckbox from '@portal/components/AdvancedCheckbox/AdvancedCheckbox';
import { cepMask, removeSpecialChars } from '@portal/utils/string';
import { maskCpfOrCnpj, maskPhone } from '@portal/services/masks';
import { bankAccountTypeArray, bankTypeArray } from '@portal/enum/bankAccount';

const formInitialValues: models.Synod = {
  name: '',
  states: [],
  document: '',
  cellphone: '',
  autoWithdraw: false,
  responsibleName: '',
  businessDescription: '',
  bankCode: '',
  bankName: '',
  bankAgency: '',
  bankAccount: '',
  bankAccountType: 1,
  zipcode: '',
  street: '',
  neighborhood: '',
  city: '',
  state: '',
  number: '',
  email: '',
};

const stateParams: IBaseFilter = {
  limit: 100,
  pageSize: 100,
  page: 1,
  orderBy: 'name',
  isDESC: 'false',
};

const SynodDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [pageType] = useState(getPagetType());

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { synod, state } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.Synod) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (synod && synod.detail) {
      const states: any = synod.detail.states?.map((item) => item.id);

      setForm({
        ...synod.detail,
        states,
        email: synod.detail.email,
        document: synod.detail.subAccount?.document,
        cellphone: synod.detail.subAccount?.cellphone,
        autoWithdraw: synod.detail.subAccount?.autoWithdraw,
        responsibleName: synod.detail.subAccount?.responsibleName,
        businessDescription: synod.detail.subAccount?.businessDescription,
        bankCode: synod.detail.subAccount?.bankCode,
        bankName: synod.detail.subAccount?.bankName,
        bankAgency: synod.detail.subAccount?.bankAgency,
        bankAccount: synod.detail.subAccount?.bankAccount,
        bankAccountType: synod.detail.subAccount?.bankAccountType,
        zipcode: synod.detail.subAccount?.address.zipcode,
        street: synod.detail.subAccount?.address.street,
        neighborhood: synod.detail.subAccount?.address.neighborhood,
        city: synod.detail.subAccount?.address.city,
        state: synod.detail.subAccount?.address.state,
        number: synod.detail.subAccount?.address.number,
      });
    } else {
      setForm(formInitialValues);
    }
  }, [synod.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(SynodActions.getDetail(params.id));
    } else {
      dispatch(SynodActions.cleanDetails());
    }
    dispatch(StateActions.getReport(stateParams));
  }, [pathname, pageType]);

  const onFormSubmit = () => {

    const formValues = Object.values(form);

    if (pageType === PAGE_TYPE.ADD) {
      for (const index in formValues) {
        if (formValues[index] === '' || formValues[index] === null) {
          return MessageService.error('APPLICATION.ERRORS.EMPTY_FORM');
        }
      }
    }

    if (!form.states?.length) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_STATES');
    }

    const formattedStates: any = [];

    form.states?.map((item) => formattedStates.push({ id: item }));

    let payload: any = null;

    if (form.document && form.cellphone) {
      payload = {
        name: form.name,
        states: formattedStates,
        email: form.email,
        subAccount: {
          document: removeSpecialChars(form.document),
          cellphone: removeSpecialChars(form.cellphone),
          autoWithdraw: form.autoWithdraw,
          responsibleName: form.responsibleName,
          businessDescription: form.businessDescription,
          bankCode: form.bankCode,
          bankName: form.bankName,
          bankAgency: form.bankAgency,
          bankAccount: form.bankAccount,
          bankAccountType: form.bankAccountType,
          address: {
            zipcode: form.zipcode,
            street: form.street,
            neighborhood: form.neighborhood,
            city: form.city,
            state: form.state,
            number: form.number,
          }
        }
      };
    } else {
      payload = {
        email: form.email,
        name: form.name,
        states: formattedStates,
      };
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(SynodActions.update(synod?.detail?.id, payload));
    } else {
      dispatch(SynodActions.create(payload));
    }
  };

  return (
    <Container
      fluid
      className="details"
    >
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.USER.SYNOD.REPORT.TITLE'),
                url: getRouteStackPath('USER', 'SYNOD_REPORT'),
                active: true,
              },
              {
                title: translate(`PAGES.PANEL.USER.SYNOD.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(`PAGES.PANEL.USER.SYNOD.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
            </h3>
            <p className="details__title__description">
              {translate(`PAGES.PANEL.USER.SYNOD.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.NAME.LABEL')}
                    value={form.name}
                    onChange={(val: string) => onFormChange('name', val)}
                  />
                </Col>
                <Col>
                  <AdvancedSelect
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.STATES.LABEL')}
                    value={form.states}
                    multiple
                    onChange={(val: string) => onFormChange('states', val)}
                    options={state.list?.rows}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.PARISH.DETAILS.FORM.FIELDS.EMAIL.LABEL'
                    )}
                    value={form.email}
                    onChange={(val: string) => onFormChange('email', val)}
                  />
                </Col>
              </Row>

              <p className="details__title__description">
                {translate(
                  'PAGES.PANEL.USER.SYNOD.DETAILS.FORM.LABELS.SECOND_SECTOR'
                )}
              </p>

              <Row>
                <Col>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.DOCUMENT.LABEL')}
                    value={maskCpfOrCnpj(form.document!)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('document', val)}
                  />
                </Col>
                <Col>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.CELLPHONE.LABEL')}
                    value={maskPhone(form.cellphone)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('cellphone', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.RESPONSIBLENAME.LABEL')}
                    value={form.responsibleName}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('responsibleName', val)}
                  />
                </Col>
                <Col>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.BUSINESSDESCRIPTION.LABEL')}
                    value={form.businessDescription}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('businessDescription', val)}
                  />
                </Col>
                <Col>
                  <AdvancedSelect
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.BANKNAME.LABEL')}
                    value={form.bankName}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => {
                      onFormChange('bankCode', val);
                      onFormChange('bankName', val);
                    }}
                    options={bankTypeArray}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.BANKAGENCY.LABEL')}
                    value={form.bankAgency}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('bankAgency', val)}
                  />
                </Col>
                <Col>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.BANKACCOUNT.LABEL')}
                    value={form.bankAccount}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('bankAccount', val)}
                  />
                </Col>
                <Col>
                  <AdvancedSelect
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.BANKACCOUNTTYPE.LABEL')}
                    value={form.bankAccountType}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('bankAccountType', val)}
                    options={bankAccountTypeArray}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AdvancedCheckbox
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.AUTOWITHDRAW.LABEL')}
                    value={form.autoWithdraw}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    onChange={(val: string) => onFormChange('autoWithdraw', val)}
                  />
                </Col>
              </Row>

              <Row>
                <Col >
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.ZIPCODE.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    value={cepMask(form.zipcode)}
                    onChange={(val: string) =>
                      onFormChange('zipcode', val)
                    }
                    type="tel"
                  />
                </Col>
              </Row>
              <Row>
                <Col >
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.STREET.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    value={form.street}
                    onChange={(val: string) =>
                      onFormChange('street', val)
                    }
                  />
                </Col>
                <Col>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.NUMBER.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    value={form.number}
                    onChange={(val: string) =>
                      onFormChange('number', val)
                    }
                    type="tel"
                  />
                </Col>
              </Row>
              <Row>
                <Col >
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.NEIGHBORHOOD.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    value={form.neighborhood}
                    onChange={(val: string) =>
                      onFormChange('neighborhood', val)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col >
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.CITY.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    value={form.city}
                    onChange={(val: string) => onFormChange('city', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col >
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.COMMUNITY.DETAILS.FORM.FIELDS.STATE.LABEL'
                    )}
                    disabled={pageType === PAGE_TYPE.EDIT}
                    value={form.state}
                    onChange={(val: string) => onFormChange('state', val)}
                  />
                </Col>
              </Row>

              <div className="details__form__actions">
                <Row className="details__form__actions__row">
                  <Col md="auto">
                    <Link to={getRouteStackPath('USER', 'SYNOD_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md="auto">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default SynodDetails;
