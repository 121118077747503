import { ProfileType } from "./profileType";

export const isMinisterArray = [
    {
        value: ProfileType.APP_USER,
        name: 'Não',
    },
    {
        value: ProfileType.MINISTER_USER,
        name: 'Sim',
    },
];