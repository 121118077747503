import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import OffersDetails from './Details/OffersReceivedDetails';
import OffersReport from './Report/OffersReceivedReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const OffersReceivedNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('OFFERS', 'OFFERS_RECEIVED_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.OFFERS_RECEIVED.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.OFFERS_RECEIVED.REPORT.DESCRIPTION')}
        >
          <OffersReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('OFFERS', 'OFFERS_RECEIVED_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.OFFERS_RECEIVED.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.OFFERS_RECEIVED.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <OffersDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default OffersReceivedNavigationStack;
