import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AppConfig from '../../config/tinymceConfig';

interface IAdvancedTextInput {
  id: string,
  value?: string,
  onChange: (value: string) => void;
}

const AdvancedTextInput = (
  {
    id,
    value,
    onChange,
  }: IAdvancedTextInput) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <Editor
        value={value}
        apiKey={AppConfig.tinyMCEKey}
        init={AppConfig.tinyMCEOptions}
        id={id}
        onEditorChange={onChange}
      />
    </div>
  );
};

export default AdvancedTextInput;