import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import {
  COMMUNITY_ALL,
  COMMUNITY_DETAIL,
  COMMUNITY_REPORT,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import CommunityRequests from '~/repositories/community';
import { ICommunityAdvancedFilter } from '~/models/advanced-filters';
import { BaseReport } from '~/models/module';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: COMMUNITY_DETAIL,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.ICommunityAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Community> = await CommunityRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: COMMUNITY_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Community = await CommunityRequests.getDetail(id);

    dispatch({
      payload,
      type: COMMUNITY_DETAIL,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.Community) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await CommunityRequests.create(params);
    MessageService.success(
      'PAGES.PANEL.USER.COMMUNITY.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    if (err.message && err.message === 'parish_not_found') {
      MessageService.error('APPLICATION.ERRORS.EMPTY_PARISH_ID');
    } else {
      MessageService.apiError(err.message);
    }

  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await CommunityRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.USER.COMMUNITY.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await CommunityRequests.update(id, params);
    MessageService.success(
      'PAGES.PANEL.USER.COMMUNITY.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getAllCommunitys = () => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const params: ICommunityAdvancedFilter = {
      limit: 100,
      pageSize: 100,
      page: 1,
      orderBy: 'createdAt',
      sort: 'desc',
    };

    const payload: BaseReport = await CommunityRequests.getAllCommunitys(
      params
    );

    dispatch({
      type: COMMUNITY_ALL,
      payload,
    });
  } catch (err: any) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
