import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';

import * as CallToActionActions from '~/actions/callToAction';

import { getRouteStackPath } from '~/config/routes';

import { getPagetType } from '~/utils/page';

import { PAGE_TYPE } from '~/enum/pageType';

import { useReduxState } from '~/hooks/useReduxState';
import AdvancedRadio from '~/components/AdvancedRadio/AdvancedRadio';
import { ACTION_TYPES, screenTypeArray } from '@portal/enum/actionType';
import AdvancedAutoComplete from '@portal/components/AdvancedAutocomplete/AdvancedAutocomplete';
import { getAllEvent } from '@portal/actions/event';

const formInitialValues: models.CallToAction = {
  name: '',
  description: '',
  screenType: null,
  url: '',
  actionType: 1,
  primaryScreenType: null,
  secondaryScreenType: null,
  detailNavigation: false,
  toScreenType: null,
};

const CallToActionDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [pageType] = useState(getPagetType());
  const [events, setEvents] = useState<models.AddSchedule[]>();
  const [finderValue, setFinderValue] = useState(0);

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { callToAction, schedule } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.CallToAction) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (callToAction && callToAction.detail) {
      setForm(callToAction.detail);
      onFormChange('primaryScreenType', callToAction.detail.toScreenType);
      onFormChange('secondaryScreenType', callToAction.detail.eventId);
    } else {
      setForm(formInitialValues);
    }
  }, [callToAction.detail]);

  useEffect(() => {
    dispatch(getAllEvent());
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CallToActionActions.getDetail(params.id));
    } else {
      dispatch(CallToActionActions.cleanDetails());
    }
  }, [pathname, pageType]);

  useEffect(() => {
    const finder = screenTypeArray.find((screen) => screen.eventType === form.primaryScreenType);
    if (finder && finder.value) {
      setFinderValue(finder.value);
    }
    const value = screenTypeArray.find((screen) => screen.eventType === form.primaryScreenType);

    if (schedule.allEvents && value) {
      const newEvents = schedule.allEvents.filter((event) => event.type === value.eventType);
      if (newEvents) {
        setEvents(newEvents);
      }
    }
  }, [form.primaryScreenType]);

  const onFormSubmit = () => {
    let toScreenType = form.toScreenType;

    if (form.secondaryScreenType) {
      toScreenType = finderValue;
    } else {
      toScreenType = form.primaryScreenType;
    }
    if (!form.name) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NAME');
    }

    if (!form.description) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_DESCRIPTION');
    }

    if (!form.screenType) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_SCREEN_TYPE');
    }

    if (form.actionType === ACTION_TYPES.EXTERNAL_NAVIGATION && !form.url) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_URL');
    }

    if (form.actionType === ACTION_TYPES.INTERNAL_NAVIGATION && !toScreenType) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_TO_SCREEN_TYPE');
    }

    let payload: models.CallToAction;

    if (form.secondaryScreenType) {
      payload = {
        ...form,
        detailNavigation: true,
        eventId: form.secondaryScreenType.toString(),
        toScreenType
      };
    } else {
      payload = {
        ...form,
        toScreenType,
      };
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(CallToActionActions.update(callToAction?.detail?.id, payload));
    } else {
      dispatch(CallToActionActions.create(payload));
    }
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.CALL_TO_ACTION.REPORT.TITLE'),
                url: getRouteStackPath('ACTIVITIES', 'CALLTOACTION_REPORT'),
                active: true,
              },
              {
                title: translate(
                  `PAGES.PANEL.CALL_TO_ACTION.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                ),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.CALL_TO_ACTION.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.CALL_TO_ACTION.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.CALL_TO_ACTION.DETAILS.FORM.FIELDS.NAME.LABEL'
                    )}
                    value={form.name}
                    onChange={(val: string) => onFormChange('name', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.CALL_TO_ACTION.DETAILS.FORM.FIELDS.DESCRIPTION.LABEL'
                    )}
                    value={form.description}
                    onChange={(val: string) => onFormChange('description', val)}
                  />
                </Col>
              </Row>
              {
                (form.screenType || pageType === PAGE_TYPE.ADD) &&
                <Row>
                  <Col md={4}>
                    <AdvancedAutoComplete
                      value={form.screenType}
                      label={translate('PAGES.PANEL.CALL_TO_ACTION.DETAILS.FORM.FIELDS.SCREEN_TYPE.LABEL')}
                      options={screenTypeArray.map((o) => ({ ...o, value: o.value, name: translate(o.name) }))}
                      onChange={(val: string | number | null) => onFormChange('screenType', val)}
                    />
                  </Col>
                </Row>
              }
              <Row>
                <Col md={12}>
                  <AdvancedRadio
                    onChange={(val: number) => onFormChange('actionType', val)}
                    label={translate(
                      'PAGES.PANEL.CALL_TO_ACTION.DETAILS.FORM.FIELDS.ACTION_TYPE.LABEL'
                    )}
                    value={form.actionType}
                    items={[
                      {
                        label: translate(
                          'ENUMERATORS.ACTION_TYPE.INTERNAL_NAVIGATION.LABEL'
                        ),
                        value: ACTION_TYPES.INTERNAL_NAVIGATION,
                      },
                      {
                        label: translate(
                          'ENUMERATORS.ACTION_TYPE.EXTERNAL_NAVIGATION.LABEL'
                        ),
                        value: ACTION_TYPES.EXTERNAL_NAVIGATION,
                      },
                    ]}
                  />
                </Col>
              </Row>
              {form.actionType === ACTION_TYPES.INTERNAL_NAVIGATION && (
                <>
                  {
                    (form.primaryScreenType || pageType === PAGE_TYPE.ADD) &&
                    <Row>
                      <Col md={4}>
                        <AdvancedAutoComplete
                          value={form.primaryScreenType}
                          label={translate('PAGES.PANEL.CALL_TO_ACTION.DETAILS.FORM.FIELDS.TO_SCREEN_TYPE.LABEL')}
                          options={screenTypeArray.map((o) => ({ ...o, value: o.eventType, name: translate(o.name) }))}
                          onChange={(val: number | null | undefined) => onFormChange('primaryScreenType', val)}
                        />
                      </Col>
                      {form.primaryScreenType && events &&
                        <Col md={4}>
                          <AdvancedAutoComplete
                            value={form.secondaryScreenType}
                            label={translate('PAGES.PANEL.CALL_TO_ACTION.DETAILS.FORM.FIELDS.TO_SCREEN_TYPE.LABEL')}
                            options={events.map((o) => ({ ...o, value: o.id, name: translate(o.title) }))}
                            onChange={(val: string | number | null) => onFormChange('secondaryScreenType', val)}
                          />
                        </Col>
                      }
                    </Row>
                  }
                </>
              )}

              {form.actionType === ACTION_TYPES.EXTERNAL_NAVIGATION && (
                <Row>
                  <Col md={4}>
                    <AdvancedInput
                      label={translate(
                        'PAGES.PANEL.CALL_TO_ACTION.DETAILS.FORM.FIELDS.URL.LABEL'
                      )}
                      value={form.url}
                      onChange={(val: string) => onFormChange('url', val)}
                    />
                  </Col>
                </Row>
              )}

              <div className="details__form__actions">
                <Row className="details__form__actions__row">
                  <Col md="auto">
                    <Link to={getRouteStackPath('ACTIVITIES', 'CALLTOACTION_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md="auto" className="text-right">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default CallToActionDetails;
