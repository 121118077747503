import { WORSHIP_REPORT, WORSHIP_DETAIL } from "@portal/actions/actionTypes";

const initialState: reducers.WorshipReducer = {
  list: null,
  detail: null,
};

const worshipReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case WORSHIP_REPORT:
      return {
        ...state,
        list: action.payload
      };

    case WORSHIP_DETAIL:
      return {
        ...state,
        detail: action.payload
      };

    default:
      return state;
  }
};

export default worshipReducer;