import PdfRequests from '~/repositories/pdf';
import { PDF_DETAILS, PDF_REPORT } from './actionTypes';
import * as MessageService from '~/services/message';
import { decreaseLoading, increaseLoading } from './loading';
import centralNavigationService from '~/services/navigation';

export const cleanDetails = () => async (dispatch: any) => {
  await dispatch({
    payload: null,
    type: PDF_DETAILS,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.INewsAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.News> = await PdfRequests.getReport({
      ...searchParams,
      offset: searchParams.page - 1,
      limit: searchParams.pageSize,
    });

    dispatch({
      payload,
      type: PDF_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Admin = await PdfRequests.getDetail(id);

    dispatch({
      payload,
      type: PDF_DETAILS,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await PdfRequests.create(params);

    MessageService.success('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.SUCCESS_ADD_MESSAGE');
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await PdfRequests.remove(id);
    MessageService.success('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.SUCCESS_REMOVE_MESSAGE');
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await PdfRequests.update(id, params);

    MessageService.success('PAGES.PANEL.NEW_ACTIVITIES.PDF.DETAILS.SUCCESS_EDIT_MESSAGE');
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
