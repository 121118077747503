import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import SubCategoryDetails from './Details/SubCategoryDetails';
import SubCategoryReport from './Report/SubCategoryReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const SubCategoryNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('OCCUPATION_AREA', 'SUBCATEGORY_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.REPORT.DESCRIPTION')}
        >
          <SubCategoryReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('OCCUPATION_AREA', 'SUBCATEGORY_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.DETAIL.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.OCCUPATION_AREA.SUB_CATEGORY.DETAIL.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <SubCategoryDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default SubCategoryNavigationStack;
