import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import * as PdfActions from '~/actions/pdf';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getRouteStackPath } from '@portal/config/routes';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { getSynodName } from '@portal/utils/page';
import { PdfType } from '~/enum/pdfType';
import { isDescArray } from '@portal/enum/isDesc';

const initialValues: advancedFilterModels.INewsAdvancedFilter = {
  title: '',
  description: '',
  fileUrl: '',
  date: '',
  type: PdfType.SINODO,
  pageSize: 10,
  page: 1,
  orderBy: 'createdAt',
  isDESC:  isDescArray[0].value,
  offset: 0,
  limit: 10,
};

const searchFields: utils.SearchParams[] = [
  {
    name: 'label',
    placeholder: 'Mês/Ano',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'date',
    placeholder: 'Data',
    type: AdvancedFilterType.DATE_PICKER,
    defaultValue: '',
    format: translate('SHARED.FORMAT_DATE'),
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
];

const PDFOfferReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { pdf, user } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();

    onSearch({
      ...advancedFilters,
      ...filter,
      synodId: user.me?.synod?.id || user.me?.user?.synodId,
      type: PdfType.SINODO,
    });
  }, [user.me]);

  const onSearch = (filters: advancedFilterModels.INewsAdvancedFilter) => {
    dispatch(PdfActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(PdfActions.remove(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
      synodId: user.me?.synod?.id || user.me?.user?.synodId,
      type: PdfType.SINODO,
    });
  };

  return (
    <div className="report">
      <Row>
        <Col>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.TITLE_SINOD')}
            pageDescription={translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.DESCRIPTION_SINOD')}
          />
        </Col>
        <Col lg={6} className="text-right">
          <Link
            to={`${getRouteStackPath('ACTIVITIES', 'PDF_SINOD_DETAILS')}/${translate(
              'SHARED.ADD_ID'
            )}`}
          >
            <AdvancedButton
              text={translate('SHARED.ADD_NEW')}
              endIcon={<PlusCircleOutlined />}
            />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              onSearch({
                ...filtersData,
                synodId: user.me?.synod?.id || user.me?.user?.synodId,
                type: PdfType.SINODO,
              })
              setAdvancedFilters(filtersData)
            }}
          />
        </Col>
      </Row>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={pdf?.list?.rows || []}
                rowCount={pdf?.list?.count || 0}
                columns={[
                  {
                    field: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.ID.KEY'),
                    headerName: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.ID.TITLE'),
                    flex: 1,
                    sortable: false,
                    hide: true,
                  },
                  {
                    field: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.TITLE.KEY'),
                    headerName: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.TITLE.TITLE'),
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.SYNOD.KEY'),
                    headerName: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.SYNOD.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {getSynodName(o.value)}
                        </>
                      );
                    }
                  },
                  {
                    field: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.UPDATE_DATE.KEY'),
                    headerName: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.UPDATE_DATE.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATE_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    align: 'center',
                    field: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.ACTIONS.KEY'),
                    headerAlign: 'center',
                    headerName: translate('PAGES.PANEL.NEW_ACTIVITIES.PDF.REPORT.FIELDS.ACTIONS.TITLE'),
                    renderCell: (o: CellParams) => {
                      return (
                        <DataTableActions
                          row={o.row}
                          basePath={getRouteStackPath('ACTIVITIES', 'PDF_SINOD_DETAILS')}
                          onRemove={onRemove}
                        />
                      );
                    },
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                    synodId: user.me?.synod?.id || user.me?.user?.synodId,
                    type: PdfType.SINODO,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PDFOfferReport;
