import OccupationAreaRequests from '~/repositories/schedule';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import {
  OCCUPATION_AREA_ALL,
  OCCUPATION_AREA_DETAILS,
  OCCUPATION_AREA_DETAILS_CATEGORY,
  OCCUPATION_AREA_REPORT,
  OCCUPATION_AREA_REPORT_CATEGORY,
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: OCCUPATION_AREA_DETAILS,
  });
};

export const cleanDetailsCategory = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: OCCUPATION_AREA_DETAILS_CATEGORY,
  });
};

export const getReportCategory = (
  searchParams: advancedFilterModels.IOccupationAreaAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Schedule> = await OccupationAreaRequests.getReportCategory(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: OCCUPATION_AREA_REPORT_CATEGORY,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getReport = (
  searchParams: advancedFilterModels.IOccupationAreaAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Schedule> = await OccupationAreaRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
        title: searchParams.name,
      }
    );

    dispatch({
      payload,
      type: OCCUPATION_AREA_REPORT,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getAllCategories = (params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    const payload = await OccupationAreaRequests.getAll(params);

    dispatch({
      type: OCCUPATION_AREA_ALL,
      payload,
    });
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Schedule = await OccupationAreaRequests.getDetail(id);

    dispatch({
      payload,
      type: OCCUPATION_AREA_DETAILS,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetailCategory = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Schedule = await OccupationAreaRequests.getDetailCategory(id);

    dispatch({
      payload,
      type: OCCUPATION_AREA_DETAILS_CATEGORY,
    });
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await OccupationAreaRequests.create(params);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.OCCUPATION_AREA.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const createCategory = (params: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await OccupationAreaRequests.createCategory(params);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.OCCUPATION_AREA.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await OccupationAreaRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.OCCUPATION_AREA.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const removeCategory = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await OccupationAreaRequests.removeCategory(id);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.OCCUPATION_AREA.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await OccupationAreaRequests.update(id, params);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.OCCUPATION_AREA.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );

    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const updateCategory = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await OccupationAreaRequests.updateCategory(id, params);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.OCCUPATION_AREA.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );

    await centralNavigationService.back();
  } catch (err: any) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
