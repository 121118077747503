import React, { useState } from 'react';
import { maskDate, maskDateTime, maskTime } from '@portal/services/masks';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import * as MessageService from '@portal/services/message';
import * as NotificationActions from '~/actions/notification';
import { getRouteStackPath } from '@portal/config/routes';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedTextInput from '@portal/components/AdvancedTextInput/AdvancedTextInput';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { AddNotifications } from '~/models/module';
import { translate } from '~/services/i18n';
import { DateTime } from 'luxon';

const initialValues: AddNotifications = {
  title: '',
  body: '',
  notificationType: null,
  scheduledDate: '',
  synodId: null,
  userIdList: null,
};

const NotificationsDetails: React.FC = (props) => {
  const [form, setForm] = useState(initialValues);
  const dispatch = useDispatch();

  const onFormChange = (key: string, value: string) => {
    setForm((prevState: models.AddNotifications) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onFormSubmit = () => {

    if (!form.title) {
      return MessageService.error(
        'APPLICATION.ERRORS.EMPTY_TITLE'
      );
    }

    if (!form.body) {
      return MessageService.error(
        'APPLICATION.ERRORS.EMPTY_DESCRIPTION'
      );
    }

    if (
      !DateTime.fromFormat(form.scheduledDate as string, translate('SHARED.FORMAT_DATE_TIME'))
        .toJSDate()
        .getTime()
    ) {
      return MessageService.error(
        translate('PAGES.PANEL.NOTIFICATIONS.ERRORS.SEND_DATE')
      );
    }

    dispatch(NotificationActions.addNotification({
      ...form,
      scheduledDate: form.scheduledDate && DateTime.fromFormat(form.scheduledDate as string, translate('SHARED.FORMAT_DATE_TIME'))
        .toJSDate().toISOString(),
    }));
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.NOTIFICATIONS.TITLE'),
                url: getRouteStackPath('ACTIVITIES', 'NOTIFICATIONS'),
                active: true,
              },
              {
                title: translate('PAGES.PANEL.PASSWORDS.REPORT.BUTTON_LINK'),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_DESCRIPTION'
            )}
          />
        </Col>
      </Row>
      <div className="notifications__space"></div>
      <Row>
        <Col lg={8}>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="notifications__space"></div>
            <Row>
              <Col lg={12}>
                <AdvancedInput
                  onChange={(value: string) => onFormChange('title', value)}
                  value={form.title}
                  label={translate(
                    'PAGES.PANEL.NOTIFICATIONS.REPORT.ADD.LABELS.TITLE'
                  )}
                />
              </Col>
            </Row>
            <div className="notifications__space">
              <Row>
                <Col>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.NOTIFICATIONS.REPORT.ADD.LABELS.DATE'
                    )}
                    onChange={(value: string) =>
                      onFormChange('scheduledDate', value)
                    }
                    value={form && maskDateTime(form.scheduledDate as string)}
                    placeholder={translate(
                      'PAGES.PANEL.NOTIFICATIONS.REPORT.ADD.LABELS.DATE_PLACEHOLDER'
                    )}
                  />
                </Col>
              </Row>
              <Row>
              <Col lg={12}>
                <div className="details__title">
                  <Row>
                    <Col md={12}>
                      <AdvancedInput
                        id="notification-form"
                        value={form.body}
                        multiline
                        onChange={(val: string) => onFormChange('body', val)}
                        placeholder={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DESCRIPTION'
                        )}
                        label={translate(
                          'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.DESCRIPTION'
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            </div>
            <div className="details__form__actions">
              <Row className="details__form__actions__row">
                <Col md="auto">
                  <Link
                    to={getRouteStackPath('ACTIVITIES', 'NOTIFICATIONS_REPORT')}
                  >
                    <AdvancedButton
                      variant="text"
                      text={translate('SHARED.BACK')}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md="auto">
                  <AdvancedButton
                    type="submit"
                    text={translate('SHARED.SAVE')}
                    endIcon={<PlusCircleOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default NotificationsDetails;
