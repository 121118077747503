import { message } from 'antd';
import Axios, { AxiosResponse } from 'axios';

import { API_URL, AUTH_API_KEY } from '@portal/config/env';
import { AuthActions } from '~/actions';
import storeCreator from '~/reducers/createStore';
import { handleAxiosError } from '~/services/api';
import { translate } from '~/services/i18n';
import * as StorageService from '~/services/storage';

const axiosInstance = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': AUTH_API_KEY,
  },
  timeout: 100000,
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (err) => {
    if (err.response.status === 401) {
      try {
        const payload: any = StorageService.getItem('session-token');
        await storeCreator.dispatch(AuthActions.refreshToken(payload));
        window.location.reload();
        await Axios(err.config);
      } catch (e) {
        // StorageService.removeItem('session-token');
      }
    } else if (err.response.status === 403) {
      message.error(translate('errors.instance.login'));
      handleAxiosError(err);
    }

    return Promise.reject(handleAxiosError(err));
  }
);

export const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
};

export function getInstance() {
  if (
    StorageService.getItem('session-token') &&
    StorageService.getItem('session-token').token
  ) {
    setAuthorizationHeader(StorageService.getItem('session-token').token);
  } else {
    setAuthorizationHeader('undefined');
  }

  return axiosInstance;
}

export default getInstance;
