import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useReduxState } from '~/hooks/useReduxState';

import * as UserActions from '~/actions/user';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import AdvancedFilter from '@portal/components/AdvancedFilter/AdvancedFilter';
import { AdvancedFilterType } from '@portal/enum/advancedFilter';
import { ProfileType } from '@portal/enum/profileType';
import { isDescArray } from '@portal/enum/isDesc';
import AdvancedButton from '@portal/components/AdvancedButton/AdvancedButton';
import { TableOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Typography, Upload } from 'antd';

import TableExample from '~/assets/png/tableExample.png';

import { message } from 'antd';
import { isMinisterArray } from '@portal/enum/isMinister';

const initialValues: advancedFilterModels.IUserAdvancedFilter = {
  name: '',
  page: 1,
  isDESC:  isDescArray[0].value,
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  profileType: [ProfileType.APP_USER, ProfileType.MINISTER_USER],
};

const searchFields: Array<utils.SearchParams> = [
  {
    name: 'name',
    placeholder: 'Nome',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'email',
    placeholder: 'E-mail',
    type: AdvancedFilterType.TEXT,
    defaultValue: '',
  },
  {
    name: 'isDESC',
    placeholder: 'Recentes ou antigas',
    type: AdvancedFilterType.SELECT,
    defaultValue: isDescArray[0].value,
    options: isDescArray
  },
  {
    name: 'profileType',
    placeholder: 'Ministro',
    type: AdvancedFilterType.SELECT,
    defaultValue: '',
    options: isMinisterArray
  },
];

const UserReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { user } = useReduxState();
  const [modalOpen, setModalOpen] = useState(false);
  const { Paragraph } = Typography;
  const [file, setFile] = useState<File | null>(null);
  const [resultUploadCsv, setResultUploadCsv] = useState<models.UploadCsvResult>();
  const [showModalResponseUploadCsv, setShowModalResponseUploadCsv] = useState(false);
  const [showUploader, setShowUploader] = useState(true);

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.IUserAdvancedFilter) => {
    dispatch(UserActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(UserActions.remove(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
      profileType: [ProfileType.APP_USER, ProfileType.MINISTER_USER],
    });
  };

  const sendCsv = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    await dispatch(UserActions.turnUserMinister(formData, (res) => { 
      if (res?.totalError > 0) {
        setResultUploadCsv(res);
        setShowModalResponseUploadCsv(true);
      } else {
        message.success(translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.SUCCESS'));
        setModalOpen(false);
        onSearch({...advancedFilters})
      }
    }));
  }

  useEffect(() => {
    setShowUploader(false);
  }, [])

  const handleCleanUpload = () => {
    setFile(null)
    setShowUploader(false)
    setTimeout(() => {
      setShowUploader(true)
    }, 10)
  }

  return (
    <div className="report">
      <Row>
        <Col>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.USER.USER.REPORT.TITLE')}
            pageDescription={translate('PAGES.PANEL.USER.USER.REPORT.DESCRIPTION')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AdvancedFilter
            fields={searchFields}
            onSearch={(filtersData: any) => {
              onSearch({ ...filtersData })
              setAdvancedFilters(filtersData)
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12} className="text-right">
          <AdvancedButton
            text={translate('PAGES.PANEL.USER.USER.REPORT.BUTTON.TEXT')}
            onClick={() => { 
              setFile(null)
              setModalOpen(true)
              setShowUploader(true)
            }}
            endIcon={<TableOutlined />}
            type="submit"
          />
        </Col>
      </Row>

      <Modal
        open={modalOpen}
        onOk={() => { 
          sendCsv()
          handleCleanUpload()
        }}
        onCancel={() => setModalOpen(false)}
        title={translate('PAGES.PANEL.USER.USER.REPORT.MODAL.TITLE').toUpperCase()}
        okText={translate('PAGES.PANEL.USER.USER.REPORT.MODAL.SEND_CSV')}
        cancelText={translate('PAGES.PANEL.USER.USER.REPORT.MODAL.CANCEL_TEXT')}
        okButtonProps={{ disabled: !file }}
      >
        <Paragraph>
          {translate('PAGES.PANEL.USER.USER.REPORT.MODAL.DESCRIPTION')}
        </Paragraph>

        <img 
          alt={translate('PAGES.PANEL.USER.USER.REPORT.MODAL.IMAGE.ALT')}
          src={TableExample} 
          className="report__image-table" 
        />

        <div className="report__csv-file-input">
          {showUploader && (
            <Upload 
              name='file'
              accept='.csv' 
              onChange={(e: any) => {
                !file &&
                setFile(e.file);
              }}
              beforeUpload={() => false}
              onRemove={() => setFile(null)}
              multiple={false}
            >
              <Button disabled={!!file} icon={<UploadOutlined />}>{translate('PAGES.PANEL.USER.USER.REPORT.MODAL.IMPORT')}</Button>
            </Upload>
          )}
          <Button icon={<UploadOutlined />} onClick={() => handleCleanUpload()}>{translate('PAGES.PANEL.USER.USER.REPORT.MODAL.CLEAR')}</Button>
        </div>

        <Modal
          open={showModalResponseUploadCsv}
          onOk={() => setShowModalResponseUploadCsv(false)}
          onCancel={() => setShowModalResponseUploadCsv(false)}
          title={translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.TITLE').toUpperCase()}
          okText={translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.CLOSE_MODAL')}
          cancelText={translate('PAGES.PANEL.USER.USER.REPORT.MODAL.CANCEL_TEXT')}
        >
          <ul className='report__csv-file-input__total-users'>
            <li>{translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.TOTAL_USERS')} {resultUploadCsv?.totalUsers}</li>
            <li>{translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.TOTAL_UPDATED')} {resultUploadCsv?.totalUpdated}</li>
            <li>{translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.TOTAL_ERRORS')} {resultUploadCsv?.totalError}</li>
          </ul>
            
          <Paragraph>
            {translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.USER_NOT_UNASSIGNED').toUpperCase()}
          </Paragraph>
          
          <div className='report__csv-table'>
            <table className='report__csv-table__csv-table-result'>
              <tr className='report__csv-table__csv-table-result__table-header'>
                <th>{translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.NAME')}</th>
                <th>{translate('PAGES.PANEL.USER.USER.REPORT.MODAL_RESULT_CSV.EMAIL')}</th>
              </tr>

                {resultUploadCsv?.withError.map((user, index) => (
                  <tr className='report__csv-table__csv-table-result__table-error'>
                    <td key={index}>{user.name}</td>
                    <td key={index}>{user.email}</td>
                  </tr>
                ))}
            </table>
          </div>
        </Modal>
      </Modal>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={user?.list?.rows || []}
                rowCount={user?.list?.count || 0}
                columns={[
                  {
                    field: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.ID.KEY'),
                    headerName: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.ID.TITLE'),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.NAME.KEY'),
                    headerName: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.NAME.TITLE'),
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.EMAIL.KEY'),
                    headerName: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.EMAIL.TITLE'),
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.CREATED_AT.KEY'),
                    headerName: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.CREATED_AT.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.IS_MINISTER.KEY'),
                    headerName: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.IS_MINISTER.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <> {
                        o.row?.profileType && o.row.profileType === ProfileType.MINISTER_USER ? 
                        translate('SHARED.YES') :
                        translate('SHARED.NO')
                      }
                      </>;
                    },
                  },
                  {
                    field: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.ACTIONS.KEY'),
                    headerName: translate('PAGES.PANEL.USER.USER.REPORT.FIELDS.ACTIONS.TITLE'),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath('USER', 'USER_DETAILS')}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                isDESC={advancedFilters.isDESC}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserReport;
