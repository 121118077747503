import getInstance from './instance';

const BASE_URL: string = '/file';

const FileApi = {
  create: async (params: models.CreateFile) => {
    const instance = getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },

  delete: async (id: any) => {
    const instance = getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  }
};

export default FileApi;
